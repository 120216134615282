import React, { useState } from "react";

import Container from "./Container";

import { LogLinesVariables } from "./Container/types/LogLines";

type Props = {
  hideIfEmpty?: boolean;
  focusedLineId?: string | null;
  focusLineHandler?: (line: string | null) => void;
  onReady?: () => void;
} & LogLinesVariables;

const LogLines: React.FunctionComponent<Props> = ({
  focusedLineId,
  focusLineHandler,
  ...rest
}) => {
  const [localFocusedLineId, setLocalFocusedLineId] = useState(focusedLineId);

  const handleLineFocus = (id: string | null) => {
    if (focusLineHandler) {
      focusLineHandler(id);
    } else {
      setLocalFocusedLineId(id);
    }
  };

  return (
    <Container
      {...{
        ...rest,
        ...{
          focusedLineId: focusedLineId || localFocusedLineId,
          focusLineHandler: handleLineFocus,
        },
      }}
    />
  );
};

export default LogLines;
