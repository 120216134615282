import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import moment from "moment-timezone";

import {
  formatBytes,
  formatMs,
  formatNumber,
  formatPercent,
  formatTimestampShort,
} from "utils/format";

import Identicon from "components/Identicon";

import styles from "./style.module.scss";

import { useRoutes } from "utils/routes";
import Grid from "components/Grid";

interface Explain {
  id: string;
  humanId: string;
  fingerprint: string;
  seenAt: number;
  planNodeTypes: string[];
  querySample: {
    runtimeMs: number;
  };
  query: {
    id: string;
  };
  totalCost: number;
  totalBlkReadTime: number;
  totalSharedBlksRead: number;
}

type FlatExplain = Omit<Explain, "querySample"> & {
  runtimeMs: number;
  pctIoTime: number;
  nodeTypesStr: string | undefined;
};

type Props = {
  databaseId: string;
  explains: Explain[];
  blockSize: number;
};

const ExplainTable: React.FunctionComponent<Props> = ({
  databaseId,
  explains,
  blockSize,
}) => {
  const { databaseQueryExplain } = useRoutes();
  const explainData = useMemo(() => {
    return explains.map<FlatExplain>((explain) => {
      const { querySample, ...rest } = explain;

      const nodeTypes = rest.planNodeTypes;
      let nodeTypesStr: string;
      if (nodeTypes) {
        const nodeCountThreshold = 5;
        const nodeShortlistLen = 3;

        const nodeCount = nodeTypes?.length;
        const elide = nodeCount > nodeCountThreshold;
        const nodes = elide ? nodeTypes.slice(0, nodeShortlistLen) : nodeTypes;
        nodeTypesStr = nodes.join(" · ");
        if (elide) {
          const extraCount =
            nodeCount && Math.max(0, nodeCount - nodeShortlistLen);
          nodeTypesStr += ` +${extraCount} more`;
        }
      }

      return {
        ...rest,
        runtimeMs: querySample && querySample.runtimeMs,
        pctIoTime:
          querySample && explain.totalBlkReadTime != null
            ? explain.totalBlkReadTime / querySample.runtimeMs
            : null,
        nodeTypesStr,
      };
    });
  }, [explains]);
  return (
    <Grid
      data={explainData}
      className="grid-cols-[minmax(20%,210px)_repeat(4,minmax(10%,100px))_64px_minmax(10%,100px)_minmax(20%,1fr)]"
      striped
      defaultSortBy="seenAt"
      noRowsText="No EXPLAIN plans found"
      columns={[
        {
          field: "seenAt",
          defaultSortOrder: "desc",
          header: "Executed at",
          renderer: function ExplainSeenAtCell({ fieldData, rowData }) {
            const explainTs = formatTimestampShort(moment.unix(fieldData));
            const explainUrl =
              rowData.query &&
              databaseQueryExplain(
                databaseId,
                rowData.query.id,
                rowData.humanId,
              );
            return explainUrl ? (
              <Link to={explainUrl}>{explainTs}</Link>
            ) : (
              explainTs
            );
          },
        },
        {
          field: "fingerprint",
          header: "Plan",
          renderer: function PlanIdCell({ fieldData }) {
            return (
              <>
                <Identicon identity={fieldData} />
                <span title={fieldData}>{fieldData.substring(0, 7)}</span>
              </>
            );
          },
        },
        {
          field: "totalCost",
          header: "Est. Cost",
          style: "number",
          nullValue: "-",
          renderer: function TotalCostCell({ fieldData }) {
            return formatNumber(fieldData);
          },
        },
        {
          field: "runtimeMs",
          header: "Runtime",
          style: "number",
          nullValue: "-",
          renderer: function RuntimeCell({ fieldData }) {
            return formatMs(fieldData);
          },
        },
        {
          field: "totalBlkReadTime",
          header: "I/O Read Time",
          style: "number",
          nullValue: "-",
          renderer: function IOReadTimeCell({ fieldData }) {
            return formatMs(fieldData);
          },
        },
        {
          field: "pctIoTime",
          header: "",
          nullValue: "-",
          renderer: function PctIOTimeCell({ fieldData }) {
            return (
              <span
                className={classNames({
                  [styles.redHighlight]: fieldData > 0.5,
                })}
              >
                {formatPercent(fieldData, 0)}
              </span>
            );
          },
        },
        {
          field: "totalSharedBlksRead",
          header: "Read From Disk",
          style: "number",
          nullValue: "-",
          renderer: function ReadFromDiskCell({ fieldData }) {
            return formatBytes(fieldData * blockSize);
          },
        },
        {
          field: "nodeTypesStr",
          header: "Plan Nodes",
          className: styles.planNodesColumn,
          headerClassName: styles.planNodesColumnHeader,
          nullValue: "-",
        },
      ]}
    />
  );
};

export default ExplainTable;
