import React, { useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import ModalContainer from "components/ModalContainer";
import { ExplainWorkspaceDetails_getExplainWorkspaceDetails as ExplainWorkspaceType } from "../ExplainWorkspace/types/ExplainWorkspaceDetails";
import ExpandableSQL from "components/ExpandableSQL";

import {
  QueryForWorkspace,
  QueryForWorkspaceVariables,
} from "../CreateWorkspacePanel/types/QueryForWorkspace";
import QUERY from "../CreateWorkspacePanel/Query.graphql";
import MUTATION from "./Mutation.graphql";
import {
  CreateExplainQuery,
  CreateExplainQueryVariables,
} from "./types/CreateExplainQuery";
import { BaselineQueryGrid } from "../CreateWorkspacePanel";
import { useNavigate } from "react-router-dom";
import { useRoutes } from "utils/routes";

const CreateExperimentPanel = ({
  onDismiss,
  workspace,
}: {
  onDismiss: () => void;
  workspace: ExplainWorkspaceType;
}) => {
  const baselineQuery = workspace.baselineQuery;

  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [queryText, setQueryText] = useState(baselineQuery.queryTextWithAlias);
  const navigate = useNavigate();
  const { databaseQueriesExplainsExperiment } = useRoutes();

  // Mutation for creating explain query (new experiment)
  const [createExplainQuery] = useMutation<
    CreateExplainQuery,
    CreateExplainQueryVariables
  >(MUTATION);

  // Query for checking if the query is valid
  const [validateQuery, { loading, data }] = useLazyQuery<
    QueryForWorkspace,
    QueryForWorkspaceVariables
  >(QUERY);

  const handleCheckQuery = () => {
    if (queryText === "") {
      setErrorMessage("Query is required");
      return;
    }
    validateQuery({
      variables: {
        databaseId: workspace.databaseId,
        query: queryText,
        baselineExplainQueryId: baselineQuery.id,
      },
      onCompleted: (data) => {
        if (!data.getQueryForWorkspace.validQuery) {
          setErrorMessage(
            "Query is not valid: " + data.getQueryForWorkspace.errorMessage,
          );
        } else {
          setErrorMessage(null);
        }
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleCreate = () => {
    if (name === "") {
      setErrorMessage("Name is required");
      return;
    }
    if (queryText === "") {
      setErrorMessage("Query Text is required");
      return;
    }

    createExplainQuery({
      variables: {
        databaseId: workspace.databaseId,
        workspaceId: workspace.id,
        explainQueryName: name,
        baselineExplainQueryId: baselineQuery.id,
        queryText,
      },
      onCompleted: (data) => {
        onDismiss();
        navigate(
          databaseQueriesExplainsExperiment(
            workspace.databaseId,
            workspace.id,
            data.createExplainQuery.id,
          ),
        );
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const validQuery = data && data.getQueryForWorkspace.validQuery;
  const experimentQueryNote = loading
    ? "Checking query..."
    : validQuery && data.getQueryForWorkspace.query == null
    ? "Could not match with existing query"
    : "";
  const queryGrid =
    validQuery && data.getQueryForWorkspace.query !== null ? (
      <BaselineQueryGrid query={data.getQueryForWorkspace.query} />
    ) : null;
  const showNextButton = validQuery;

  return (
    <ModalContainer title="New experiment" onClose={onDismiss}>
      <div className="grid grid-cols-2 mt-4">
        <div className="mb-1 font-medium col-span-2">Name</div>
        <input
          className="col-span-2 bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5"
          type="text"
          onChange={(e) => setName(e.target.value)}
          value={name}
          placeholder="Name of new experiment"
        />
      </div>
      <div className="mt-4">
        <div className="mb-1 font-medium">Baseline Query</div>
        <ExpandableSQL sql={baselineQuery.queryTextWithAlias} />
      </div>
      <div className="grid grid-cols-2 mt-4">
        <div className="mb-1 font-medium">Experiment Query</div>
        <div className="mb-1 justify-self-end text-[#606060]">
          {experimentQueryNote}
        </div>
        <div className="col-span-2">
          {queryGrid ? (
            queryGrid
          ) : (
            <textarea
              className="bg-white rounded border border-gray-300 box-border w-full leading-5 px-2 py-1.5 disabled:bg-[#eee]"
              onChange={(e) => setQueryText(e.target.value)}
              value={queryText}
              placeholder="Start by pasting the query SQL to match with existing query in pganalyze"
              rows={10}
              disabled={showNextButton}
            />
          )}
        </div>
      </div>
      <div className="mt-2 text-[#FF0000]">{errorMessage}</div>
      <div className="text-right mt-4">
        <button className="btn !px-10" onClick={onDismiss}>
          Cancel
        </button>
        {showNextButton ? (
          <button className="btn btn-success !w-[140px]" onClick={handleCreate}>
            Next
          </button>
        ) : (
          <button
            className="btn btn-success !w-[140px]"
            onClick={handleCheckQuery}
          >
            Check Query
          </button>
        )}
      </div>
    </ModalContainer>
  );
};

export default CreateExperimentPanel;
