import React, { useEffect, useState } from "react";

import { useSelectedNode } from "components/Explain/WithNodeSelection";
import NodeDetails from "components/ExplainSidebar/NodeDetails";
import Source from "components/ExplainSidebar/NodeDetails/Source";
import PillButtonBar from "components/PillButtonBar";

import { ExplainPlanType } from "components/Explain/util";

import { AnnotatedPlan } from "types/explain";
import { usePrevious } from "utils/hooks";
import ExplainStatsAndOptions from "./ExplainStatsAndOptions";
import ExplainInsights from "./ExplainInsights";
import ExplainDisplayControls from "components/ExplainPanel/ExplainDisplayControls";

const ExplainSidebar = ({
  explain,
  blockSize,
  plan,
  databaseId,
  summaryOnly,
}: {
  explain: ExplainPlanType;
  blockSize: number;
  plan: AnnotatedPlan;
  databaseId: string;
  summaryOnly: boolean;
}) => {
  const [section, setSection] = useState<
    "summary" | "node details" | "node source"
  >("summary");
  const [selectedNode] = useSelectedNode();
  const selectedId = selectedNode?.extra.id;
  const previousSelectedId = usePrevious(selectedId);
  const switchToNodeDetails =
    !summaryOnly &&
    section === "summary" &&
    selectedId &&
    selectedId !== previousSelectedId;
  const opts = [
    { value: "summary", label: "Summary" },
    { value: "node details", label: "Node Details" },
    { value: "node source", label: "Node Source" },
  ] as const;
  const actualSection = summaryOnly ? "summary" : section;

  useEffect(() => {
    if (switchToNodeDetails) {
      setSection("node details");
    }
  }, [switchToNodeDetails]);
  return (
    <div>
      <PillButtonBar
        opts={opts.filter((opt) => !summaryOnly || opt.value === "summary")}
        selected={actualSection}
        onChange={(value) => setSection(value)}
      />
      <div className="mt-4">
        {actualSection === "summary" ? (
          <>
            <ExplainStatsAndOptions
              seenAt={explain.seenAt}
              fingerprint={explain.fingerprint}
              totCost={explain.totalCost}
              ioBlocks={explain.totalSharedBlksRead}
              ioMs={explain.totalBlkReadTime}
              plan={plan.plan}
              runtime={explain.querySample.runtimeMs}
              blockSize={blockSize}
            />
            <hr />
            <ExplainInsights annotations={plan.annotations} />
            <hr />
            <ExplainDisplayControls />
          </>
        ) : actualSection === "node details" ? (
          <NodeDetails
            databaseId={databaseId}
            blockSize={blockSize}
            annotations={plan.annotations}
          />
        ) : actualSection === "node source" ? (
          <Source />
        ) : null}
      </div>
    </div>
  );
};

export default ExplainSidebar;
