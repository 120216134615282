import { useQuery } from "@apollo/client";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import QUERY from "./Query.graphql";
import {
  ExplainWorkspaceDetails,
  ExplainWorkspaceDetailsVariables,
  ExplainWorkspaceDetails_getExplainWorkspaceDetails as ExplainWorkspaceType,
  ExplainWorkspaceDetails_getExplainWorkspaceDetails_explainQueries as ExplainQueryType,
} from "./types/ExplainWorkspaceDetails";
import Loading from "components/Loading";
import { formatDateMonthDay } from "utils/format";
import moment from "moment";
import CreateExperimentPanel from "../CreateExperimentPanel";
import PanelSection from "components/PanelSection";
import ExplainWorkspaceParameterSets from "./ExplainWorkspaceParameterSets";
import ExplainWorkspaceRunExplain from "./ExplainWorkspaceRunExplain";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import { useRoutes } from "utils/routes";
import ExplainExperiment from "../ExplainExperiment";
import ExplainWorkspaceParameterSetsEdit from "./ExplainWorkspaceParameterSetsEdit";
import Grid, { MsCell } from "components/Grid";
import FilterSearch from "components/FilterSearch";
import { makeFilter } from "utils/filter";
import Popover from "components/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint, faUser } from "@fortawesome/pro-solid-svg-icons";
import QueryTags, { QueryTagType } from "components/QueryTags";

const ExplainWorkspace = () => {
  const { databaseId, workspaceId, explainQueryId } = useParams();
  const [showNewExperimentPanel, setShowNewExperimentPanel] = useState(false);
  const {
    databaseQueriesExplainsWorkspace,
    databaseQueriesExplainsExperiment,
  } = useRoutes();

  const { loading, error, data, refetch } = useQuery<
    ExplainWorkspaceDetails,
    ExplainWorkspaceDetailsVariables
  >(QUERY, {
    variables: { workspaceId, databaseId },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const workspace = data.getExplainWorkspaceDetails;
  const baselineQuery = workspace.baselineQuery;
  // focusedQuery is null if this component is rendered without focusing on any query
  // (initial workspace creation flow or overview page)
  const focusedQuery =
    baselineQuery.id === explainQueryId
      ? baselineQuery
      : workspace.explainQueries.find((val) => val.id === explainQueryId);

  const featureNav = (
    <PageSecondaryNavigation>
      <PageNavLink
        to={databaseQueriesExplainsWorkspace(databaseId, workspace.id)}
      >
        Overview
      </PageNavLink>
      <PageNavLink
        to={databaseQueriesExplainsExperiment(
          databaseId,
          workspace.id,
          baselineQuery.id,
        )}
      >
        Baseline
      </PageNavLink>
      {workspace.explainQueries.map((explainQuery) => {
        return (
          <PageNavLink
            key={explainQuery.id}
            to={databaseQueriesExplainsExperiment(
              databaseId,
              workspace.id,
              explainQuery.id,
            )}
          >
            {explainQuery.name}
          </PageNavLink>
        );
      })}
    </PageSecondaryNavigation>
  );

  // check if the result is uploaded or not for the provided explain query
  const resultUploaded = (explainQuery: ExplainQueryType): boolean => {
    return (
      explainQuery.explainResults.length > 0 &&
      (workspace.parameterSets.length === 0 ||
        explainQuery.explainResults.length === workspace.parameterSets.length)
    );
  };

  // Pick up or update parameter sets
  // (workspace.parameterSetsSelected can be false when param sets re-selection is required)
  if (!workspace.parameterSetsSelected) {
    // In the initial workspace creation flow
    if (explainQueryId == null && !resultUploaded(baselineQuery)) {
      return <ExplainWorkspaceParameterSets workspace={workspace} />;
    }
    // In the re-selection flow
    if (explainQueryId != null && !resultUploaded(focusedQuery)) {
      return (
        <ExplainWorkspaceParameterSetsEdit
          workspace={workspace}
          explainQuery={focusedQuery || baselineQuery}
          featureNav={featureNav}
        />
      );
    }
  }

  // Run EXPLAIN
  // In the initial workspace creation flow (baselineQuery result not uploaded)
  // or the new experiment creation flow (focusedQuery result not uploaded)
  if (!resultUploaded(focusedQuery || baselineQuery)) {
    return (
      <ExplainWorkspaceRunExplain
        workspace={workspace}
        explainQuery={focusedQuery || baselineQuery}
        featureNav={focusedQuery && featureNav}
      />
    );
  }

  function handleUploadDismiss() {
    setShowNewExperimentPanel(false);
    refetch();
  }

  const sideButton = (
    <button
      className="btn btn-success"
      onClick={() => setShowNewExperimentPanel(true)}
    >
      Record New Experiment
    </button>
  );

  return (
    <PageContent
      windowTitle={`EXPLAIN Workspace: ${workspace.name}`}
      featureInfo={
        <ExplainWorkspaceHeader workspace={workspace} sideButton={sideButton} />
      }
      pageCategory="explains"
      pageName="workspaces"
      layout="sidebar"
      featureNav={featureNav}
    >
      {showNewExperimentPanel && (
        <CreateExperimentPanel
          onDismiss={handleUploadDismiss}
          workspace={workspace}
        />
      )}
      {focusedQuery ? (
        <ExplainExperiment
          workspace={workspace}
          explainQuery={focusedQuery}
          blockSize={data.getServerDetails.blockSize}
        />
      ) : (
        <ExplainOverviewPanel
          databaseId={databaseId}
          workspace={workspace}
          recordNewExperimentButton={sideButton}
        />
      )}
      {/* sidebar */}
      <ExplainExperimentSidebar
        workspace={workspace}
        baselineQuery={baselineQuery}
      />
    </PageContent>
  );
};

const ExplainOverviewPanel = ({
  databaseId,
  workspace,
  recordNewExperimentButton,
}: {
  databaseId: string;
  workspace: ExplainWorkspaceType;
  recordNewExperimentButton?: React.ReactNode;
}) => {
  const { databaseQueriesExplainsExperiment } = useRoutes();
  const [searchTerm, setSearchTerm] = useState("");
  const secondaryTitle = (
    <FilterSearch
      initialValue={searchTerm}
      onChange={setSearchTerm}
      placeholder="Search experiments..."
    />
  );

  const experiments = [
    workspace.baselineQuery as ExplainQueryType,
    ...workspace.explainQueries,
  ].map((exp) => {
    const runtimeValues = exp.explainResults.map((val) => val.runtimeMs);

    return {
      id: exp.id,
      name: exp.name,
      resultCount: exp.explainResults.length,
      minRuntime: runtimeValues.length > 0 ? Math.min(...runtimeValues) : null,
      maxRuntime: runtimeValues.length > 0 ? Math.max(...runtimeValues) : null,
    };
  });

  const filteredData = experiments.filter(makeFilter(searchTerm, "name"));

  return (
    <Panel title="Experiments" secondaryTitle={secondaryTitle}>
      <Grid
        className="grid-cols-[1fr_repeat(3,minmax(15%,120px))]"
        data={filteredData}
        columns={[
          {
            field: "name",
            header: "Experiment",
            renderer: function NameCell({ rowData, fieldData }) {
              return (
                <Link
                  to={databaseQueriesExplainsExperiment(
                    databaseId,
                    workspace.id,
                    rowData.id,
                  )}
                >
                  {fieldData}
                </Link>
              );
            },
          },
          {
            field: "resultCount",
            header: "Query Plans",
            style: "number",
          },
          {
            field: "minRuntime",
            header: "Min Runtime",
            nullValue: "-",
            renderer: MsCell,
            style: "number",
          },
          {
            field: "maxRuntime",
            header: "Max Runtime",
            nullValue: "-",
            renderer: MsCell,
            style: "number",
          },
        ]}
      />
      <PanelSection>{recordNewExperimentButton}</PanelSection>
    </Panel>
  );
};

export const ExplainWorkspaceHeader = ({
  workspace,
  sideButton,
}: {
  workspace: ExplainWorkspaceType;
  sideButton?: React.ReactNode;
}) => {
  return (
    <div className="flex items-center">
      <div className="grow">
        <h2 className="text-[#606060] m-0 py-[9px] font-medium leading-[26px] text-[22px]">
          {workspace.name}
        </h2>
        <div>
          {workspace.user?.fullname || "Deleted User"} · Edited{" "}
          {formatDateMonthDay(moment.unix(workspace.lastActivityAt))}
        </div>
      </div>
      <div className="justify-end">{sideButton}</div>
    </div>
  );
};

export const ExplainExperimentSidebar = ({
  workspace,
  baselineQuery,
}: {
  workspace: ExplainWorkspaceType;
  baselineQuery: ExplainQueryType;
}) => {
  const { databaseId } = useParams();
  const { databaseQuery } = useRoutes();

  const queryTags: QueryTagType[] = [];
  if (baselineQuery.query) {
    queryTags.push(
      ...[
        {
          id: "fingerprint",
          key: (
            <Popover
              content="A query fingerprint represents the abstracted form of a query and enables the grouping of similar queries together. This value is calculated using the pg_query library."
              popupClassName="!text-[12px]"
            >
              <FontAwesomeIcon
                icon={faFingerprint}
                className="text-[#666] text-[10px]"
              />{" "}
              fingerprint
            </Popover>
          ),
          value: baselineQuery.queryFingerprint,
        },
        {
          id: "role",
          key: (
            <>
              <FontAwesomeIcon
                icon={faUser}
                className="text-[#666] text-[10px]"
              />{" "}
              role
            </>
          ),
          value: baselineQuery.query.postgresRole.name,
        },
      ],
    );
  }

  return (
    <div className="w-[320px]">
      <h4 className="leading-7 mt-0">Description</h4>
      <div className="mb-3">{workspace.description}</div>
      <h4 className="leading-7 mt-0">Baseline Query Info</h4>
      <div className="mb-3">
        {baselineQuery?.query ? (
          <Link to={databaseQuery(databaseId, baselineQuery.query.id)}>
            #{baselineQuery.query.id}
          </Link>
        ) : (
          "No match with existing queries"
        )}
      </div>
      <h4 className="leading-7 mt-0">Query tags</h4>
      <div className="mb-3">
        <QueryTags tags={queryTags} />
      </div>
    </div>
  );
};

export default ExplainWorkspace;
