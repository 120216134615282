import React, { useMemo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

import Loading from "components/Loading";
import Dropdown from "components/Dropdown";
import { useRelatedRoute, useRoutes } from "utils/routes";
import { useUserPreferences } from "utils/hooks";
import { mru } from "utils/array";

import {
  DatabaseSelect as DatabaseSelectType,
  DatabaseSelectVariables,
  DatabaseSelect_getDatabases as DatabaseType,
} from "./types/DatabaseSelect";
import QUERY from "./Query.graphql";
import styles from "../style.module.scss";
import { useCurrentServer } from "components/WithCurrentOrganization";
import { CaretIcon } from "components/Icons";
import { faBan, faEllipsisV } from "@fortawesome/pro-solid-svg-icons";

const DatabaseSelect: React.FunctionComponent = () => {
  const currentServer = useCurrentServer();
  const { databaseId: currentDatabaseId } = useParams();
  const currentServerId = currentServer.humanId;
  const [dbSelect, setDbSelect] = useUserPreferences("databaseSelect");

  const recentDbIds = useMemo(() => {
    return (dbSelect.recentDbIds || {})[currentServerId] || [];
  }, [dbSelect.recentDbIds, currentServerId]);

  const { server: serverRoot, database: databaseRoot } = useRoutes();
  const { relatedDatabaseRoute, relatedServerRoute } = useRelatedRoute();

  useEffect(() => {
    if (currentDatabaseId && recentDbIds[0] != currentDatabaseId) {
      setDbSelect({
        ...dbSelect,
        recentDbIds: {
          ...dbSelect.recentDbIds,
          [currentServerId]: mru(currentDatabaseId, recentDbIds, 5),
        },
      });
    }
  }, [setDbSelect, dbSelect, currentServerId, recentDbIds, currentDatabaseId]);

  const { data, loading, error } = useQuery<
    DatabaseSelectType,
    DatabaseSelectVariables
  >(QUERY, {
    variables: {
      serverId: currentServer.humanId,
    },
  });
  if (loading || error) {
    return <HeaderLoading error={!!error} />;
  }

  const databases = data.getDatabases;
  const visibleDbs = databases.filter((d) => !d.hidden);

  const topDbs = visibleDbs
    .slice()
    .sort((a, b) => {
      if (recentDbIds.includes(a.id) && recentDbIds.includes(b.id)) {
        return recentDbIds.indexOf(a.id) - recentDbIds.indexOf(b.id);
      }
      if (recentDbIds.includes(a.id)) {
        return -1;
      }
      if (recentDbIds.includes(b.id)) {
        return 1;
      }
      return a.datname.localeCompare(b.datname);
    })
    .slice(0, 5);

  const extraDbs = visibleDbs.length - topDbs.length;

  const currentDatabase = databases.find((d) => d.id === currentDatabaseId);
  const canOpen =
    (visibleDbs.length > 1 || (visibleDbs.length === 1 && !currentDatabase)) &&
    (relatedDatabaseRoute(visibleDbs[0].id) != databaseRoot(visibleDbs[0].id) ||
      relatedServerRoute(currentServerId) == serverRoot(currentServerId));

  const trigger = ({
    open,
    toggleOpen,
  }: {
    open: boolean;
    toggleOpen: () => void;
  }) => (
    <button onClick={toggleOpen}>
      <small>Database</small>
      <div className={styles.selectMenuCurrent}>
        {currentDatabase ? (
          <DatabaseItemContent database={currentDatabase} />
        ) : (
          <>
            <span>(no database)</span>
          </>
        )}
        {currentDatabase && (
          <Link
            to={relatedServerRoute(currentServerId)}
            className={styles.closeLink}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Link>
        )}
        <CaretIcon direction={open ? "up" : "down"} headerNav />
      </div>
    </button>
  );

  return (
    <li className={styles.selectMenu} data-select-menu="database">
      {canOpen ? (
        <Dropdown trigger={trigger} className={styles.dropdown}>
          {({ setClosed }) => {
            return (
              <ul className={styles.selectMenuList}>
                {topDbs.map((d) => (
                  <li key={d.id}>
                    <Link
                      className={styles.selectMenuListItem}
                      to={relatedDatabaseRoute(d.id)}
                      onClick={setClosed}
                    >
                      <DatabaseItemContent database={d} />
                    </Link>
                  </li>
                ))}
                {extraDbs > 0 && (
                  <ExtraDbsItem
                    extraDbs={extraDbs}
                    serverId={currentServer.humanId}
                  />
                )}
              </ul>
            );
          }}
        </Dropdown>
      ) : (
        <div>
          <div className={styles.noOptions}>
            <small>Database</small>
            <div className={styles.selectMenuCurrent}>
              {currentDatabase ? (
                <DatabaseItemContent database={currentDatabase} />
              ) : (
                <FontAwesomeIcon
                  icon={faBan}
                  className="text-[#ccc] mt-[1px]"
                />
              )}
              {currentDatabase && (
                <Link
                  to={relatedServerRoute(currentServerId)}
                  className={styles.closeLink}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

const ExtraDbsItem: React.FunctionComponent<{
  extraDbs: number;
  serverId: string;
}> = ({ extraDbs, serverId }) => {
  const { server } = useRoutes();
  return (
    <li>
      <Link className={styles.selectMenuListItem} to={server(serverId)}>
        <FontAwesomeIcon icon={faEllipsisV} />
        <FontAwesomeIcon
          icon={faEllipsisV}
          className="text-[10px] mr-[2px]"
          fixedWidth
        />
        <span className="ml-[4px]">Show {extraDbs} more</span>
      </Link>
    </li>
  );
};

const DatabaseItemContent: React.FunctionComponent<{
  database: DatabaseType;
}> = ({ database }) => {
  return <span>{database.datname || "Database #" + database.id}</span>;
};

const HeaderLoading: React.FunctionComponent<{ error: boolean }> = ({
  error,
}) => {
  return <Loading className={styles.loading} small error={error} />;
};

export default DatabaseSelect;
