import React, { useState } from "react";

import Tip from "components/Tip";

type Props = {
  server?: {
    dbName: string | null;
    dbUsername: string | null;
    dbHost: string | null;
    dbPort: number | null;
    dbSslmode: string | null;
    dbUseIamAuth: boolean | null;
    awsRegion: string | null;
    awsDbInstanceId: string | null;
    awsDbClusterId: string | null;
    awsAssumeRole: string | null;
    enableLogs: boolean | null;
    enableActivity: boolean | null;
  };
};

export const ServerLocalCollectorSettings: React.FunctionComponent<Props> = ({
  server,
}) => {
  const [showAwsSettings, setShowAwsSettings] = useState(false);

  return (
    <div className="row mb-[10px]">
      <div className="col-sm-6">
        <div className="form-group">
          <label className="control-label" htmlFor="server_db_name">
            Database Name
          </label>
          <input
            className="form-control"
            type="text"
            name="server[db_name]"
            id="server_db_name"
            placeholder="mydb"
            defaultValue={server?.dbName}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="server_db_username">
            Database Username
          </label>
          <input
            className="form-control"
            type="text"
            name="server[db_username]"
            id="server_db_username"
            placeholder="someuser"
            defaultValue={server?.dbUsername}
          />
        </div>
      </div>
      <div className="col-sm-12 mt-[10px] mb-[10px]">
        <div className="form-group">
          <label className="control-label" htmlFor="server_db_password">
            Database Password
          </label>
          <div className="row">
            <div className="col-sm-6">
              <input
                className="form-control"
                type="password"
                name="server[db_password]"
                id="server_db_password"
              />
            </div>
            {server && (
              <div className="col-sm-6">
                <div className="help-block">
                  Leave empty to keep current password
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label className="control-label" htmlFor="server_db_host">
            Hostname
          </label>
          <input
            className="form-control"
            type="text"
            name="server[db_host]"
            id="server_db_host"
            placeholder="my-database.example.com"
            defaultValue={server?.dbHost}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="server_db_port">
            PostgreSQL Port
          </label>
          <input
            className="form-control"
            type="text"
            name="server[db_port]"
            id="server_db_port"
            placeholder="5432"
            defaultValue={server ? String(server.dbPort) : "5432"}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="server_db_sslmode">
            PostgreSQL SSL Mode
          </label>
          <select
            className="form-control"
            name="server[db_sslmode]"
            defaultValue={server?.dbSslmode}
          >
            <option value="prefer">Prefer</option>
            <option value="require">Require</option>
            <option value="aws_verify_full">
              Require &amp; Verify Full (Amazon RDS &amp; Amazon Aurora)
            </option>
            <option value="disable">Disable</option>
          </select>
        </div>
        {showAwsSettings ? (
          <>
            <div className="form-group">
              <label className="control-label" htmlFor="server_aws_region">
                AWS Region (optional)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[aws_region]"
                id="server_aws_region"
                defaultValue={server?.awsRegion}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_aws_db_instance_id"
              >
                AWS DB Instance ID (optional)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[aws_db_instance_id]"
                id="server_aws_db_instance_id"
                defaultValue={server?.awsDbInstanceId}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="server_aws_db_cluster_id"
              >
                AWS DB Cluster ID (optional)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[aws_db_cluster_id]"
                id="server_aws_db_cluster_id"
                defaultValue={server?.awsDbClusterId}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="server_aws_assume_role">
                AWS Assume Role ARN (optional)
              </label>
              <input
                className="form-control"
                type="text"
                name="server[aws_assume_role]"
                id="server_aws_assume_role"
                defaultValue={server?.awsAssumeRole}
              />
            </div>
            <div>
              <input type="hidden" name="server[db_use_iam_auth]" value="0" />
              <input
                type="checkbox"
                name="server[db_use_iam_auth]"
                id="server[db_use_iam_auth]"
                value="1"
                defaultChecked={server ? server.dbUseIamAuth : false}
                style={{ margin: 0 }}
              />
              <label
                htmlFor="server[db_use_iam_auth]"
                className="ml-[3px] inline"
              >
                Use IAM authentication{" "}
                <Tip
                  content={`To use this setting, leave password empty and make sure IAM authentication is enabled in AWS, the pganalyze IAM policy includes the "rds-db:connect" privilege for the pganalyze user, and the user is granted the "rds_iam" role in Postgres.`}
                />
              </label>
            </div>
          </>
        ) : null}
      </div>
      <div className="col-sm-12 mt-[5px]">
        {!showAwsSettings ? (
          <a
            href=""
            onClick={(evt) => {
              evt.preventDefault();
              setShowAwsSettings(true);
            }}
            className="block mb-2"
          >
            Show additional settings for Amazon RDS &amp; Aurora
          </a>
        ) : null}
        {!server && (
          <div>
            <input type="hidden" name="collect_all_databases" value="0" />
            <input
              type="checkbox"
              name="collect_all_databases"
              id="collect_all_databases"
              value="1"
              defaultChecked={true}
              style={{ margin: 0 }}
            />
            <label htmlFor="collect_all_databases" className="ml-[3px] inline">
              Collect statistics from all databases on this server
            </label>
          </div>
        )}
        <div>
          <input type="hidden" name="server[enable_logs]" value="0" />
          <input
            type="checkbox"
            name="server[enable_logs]"
            id="server[enable_logs]"
            value="1"
            defaultChecked={server ? server.enableLogs : false}
            style={{ margin: 0 }}
          />
          <label htmlFor="server[enable_logs]" className="ml-[3px] inline">
            Enable Log Insights (Amazon RDS &amp; Aurora and{" "}
            <a href="https://pganalyze.com/docs/install/troubleshooting/pg_read_file_helper">
              self-managed servers
            </a>
            )
          </label>
        </div>
        <div>
          <input type="hidden" name="server[enable_activity]" value="0" />
          <input
            type="checkbox"
            name="server[enable_activity]"
            id="server[enable_activity]"
            value="1"
            defaultChecked={server ? server.enableActivity : true}
            style={{ margin: 0 }}
          />
          <label htmlFor="server[enable_activity]" className="ml-[3px] inline">
            Enable Activity Sampling
          </label>
        </div>
      </div>
    </div>
  );
};

export default ServerLocalCollectorSettings;
