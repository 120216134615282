import React from "react";

import { useParams } from "react-router-dom";

import PageContent from "components/PageContent";
import DateRangeBar from "components/DateRangeBar";
import { useRoutes } from "utils/routes";
import { useFeature } from "components/OrganizationFeatures";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import QueryList from "./QueryList";
import QuerySearch from "./QuerySearch";

type QueryPerformanceTabType = "overview" | "search";

const QueryPerformance: React.FunctionComponent<{
  tab: QueryPerformanceTabType;
}> = ({ tab }) => {
  const { databaseId } = useParams();
  const hasSearchFeature = useFeature("querySearch");
  const { databaseQueries, databaseQueriesSearch } = useRoutes();

  const featureNav = (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseQueries(databaseId)}>Overview</PageNavLink>
      <PageNavLink to={databaseQueriesSearch(databaseId)}>Search</PageNavLink>
    </PageSecondaryNavigation>
  );

  return (
    <PageContent
      title="Query Performance"
      pageControls={<DateRangeBar />}
      pageCategory="queries"
      pageName="index"
      featureNav={hasSearchFeature && featureNav}
    >
      <QueryPerformanceTab tab={tab} />
    </PageContent>
  );
};

const QueryPerformanceTab: React.FunctionComponent<{
  tab: QueryPerformanceTabType;
}> = ({ tab }) => {
  switch (tab) {
    case "overview":
      return <QueryList />;
    case "search":
      return <QuerySearch />;
  }
};

export default QueryPerformance;
