import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Navigate, useParams } from "react-router-dom";

import Form from "components/Form";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { ServerLocalCollectorSettings } from "components/ServerLocalCollectorSettings";
import { useAppConfig } from "components/WithAppConfig";
import { useRoutes } from "utils/routes";
import {
  ServerLocalCollectorNew as ServerLocalCollectorNewType,
  ServerLocalCollectorNewVariables,
} from "./types/ServerLocalCollectorNew";

import QUERY from "./Query.graphql";
import styles from "./style.module.scss";

const ServerLocalCollectorNew: React.FunctionComponent = () => {
  const { organizationServers, organizationServersInstall } = useRoutes();
  const { slug: organizationSlug } = useParams();
  const { enterpriseEdition } = useAppConfig();
  const [showLocalCollector, setShowLocalCollector] = useState(false);
  const [showCollectorInstall, setShowCollectorInstall] = useState(false);

  const { data } = useQuery<
    ServerLocalCollectorNewType,
    ServerLocalCollectorNewVariables
  >(QUERY, {
    variables: { organizationSlug },
  });
  const apiKey = data?.getApiKeys?.[0]?.token;

  if (!enterpriseEdition) {
    return (
      <Navigate to={organizationServersInstall(organizationSlug)} replace />
    );
  }

  const handleResetClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowLocalCollector(false);
    setShowCollectorInstall(false);
  };

  const handleLocalCollectorClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    setShowLocalCollector(true);
    setShowCollectorInstall(false);
  };

  const handleCollectorInstallClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    setShowLocalCollector(false);
    setShowCollectorInstall(true);
  };

  return (
    <PageContent title="Add Server" pageCategory="servers" pageName="new">
      <div style={{ maxWidth: "1000px", fontSize: "16px" }}>
        <Panel title="Add Server">
          {!showLocalCollector && !showCollectorInstall ? (
            <PanelSection>
              <p>
                Please choose where you would like to run the pganalyze
                collector, which connects to your database server to collect
                metrics and logs.
              </p>
              <p>
                <strong>
                  Collector inside pganalyze Enterprise Server container
                </strong>
                <br />
                The easiest way to get started. This method requires that you
                run a single pganalyze Enterprise Server container, not multiple
                separate containers. This works best when monitoring a small
                environment using Amazon RDS / Aurora. This method does not
                support integrating Log Insights / Automated EXPLAIN for other
                cloud providers.
              </p>
              <p>
                This is a pull-based method, where Postgres connections are made
                from the central installation going to your database
                environment.
              </p>
              <p>
                <strong>Separate collector installation</strong>
                <br />
                For installations that have a more complex network setup,
                require customized collector settings, or are monitoring Google
                Cloud SQL, Azure Database for Postgres or other managed service
                providers which are not Amazon RDS / Aurora.
              </p>
              <p>
                This is a push-based method that submits data to the central
                installation using a collector API key. This method supports
                scaled out pganalyze Enterprise Server installations with
                multiple separate containers running.
              </p>
              <hr />
              <div className={styles.choiceButtonContainer}>
                <button
                  className={styles.choiceButton}
                  onClick={handleLocalCollectorClick}
                >
                  Collector inside pganalyze Enterprise Server container
                  <span>Best for getting started, small installations.</span>
                </button>
                <button
                  className={styles.choiceButton}
                  onClick={handleCollectorInstallClick}
                >
                  Separate collector installation
                  <span>
                    Full configuration options, scale out installations.
                  </span>
                </button>
              </div>
            </PanelSection>
          ) : showLocalCollector ? (
            <>
              <PanelSection>
                <p>
                  <strong>
                    Collector inside pganalyze Enterprise Server container
                  </strong>{" "}
                  <a href="" onClick={handleResetClick}>
                    (change)
                  </a>
                </p>
                <p>
                  Please specify the connection information for the Postgres
                  server you'd like to monitor. The collector inside the
                  pganalyze Enterprise Server container will connect to your
                  database server using the Postgres protocol to fetch data.
                </p>
                <p>
                  You'll need to either set up a{" "}
                  <strong>
                    <a href="https://pganalyze.com/docs/install/amazon_rds/02_create_monitoring_user">
                      restricted monitoring user
                    </a>
                  </strong>{" "}
                  or specify superuser credentials.
                </p>
              </PanelSection>
              <Form
                action={organizationServers(organizationSlug)}
                method="post"
                className="text-[14px]"
              >
                <ServerLocalCollectorSettings />
                <div className="form-group">
                  <input
                    type="submit"
                    name="commit"
                    value="Add Server"
                    className="btn btn-success"
                    data-disable-with="Add Server"
                  />
                </div>
              </Form>
            </>
          ) : (
            <PanelSection>
              <p>
                <strong>Separate collector installation</strong>{" "}
                <a href="" onClick={handleResetClick}>
                  (change)
                </a>
              </p>
              <p>
                Before starting the collector installation, make sure you have{" "}
                <a href="https://pganalyze.com/docs/enterprise/setup/object-storage">
                  followed the instructions for setting up the object storage
                </a>
                , for example with Amazon S3 or Minio, that is required for
                sending collector snapshots to the central pganalyze Enterprise
                Server installation.
              </p>
              <p>
                Continue by following the cloud-based{" "}
                <a href="https://pganalyze.com/docs/install">
                  collector installation instructions
                </a>{" "}
                to install the collector either on your database server directly
                (for self-managed servers) or on a container/VM close to your
                database server.
              </p>
              <p>
                When configuring the collector, specify the following
                configuration settings in the collector config file:
              </p>
              <pre>
                {"[pganalyze]\napi_base_url = http://" +
                  window.location.hostname +
                  ":" +
                  (window.location.port || 80) +
                  "\napi_key = " +
                  (apiKey || "REPLACE_ME_REPLACE_ME")}
              </pre>
              <p>
                When running the collector in a container these settings are
                instead called <code>PGA_API_KEY</code> and{" "}
                <code>PGA_API_BASEURL</code>.
              </p>
              <p>
                You can find the collector API key on the Settings page. The API
                base URL needs to be reachable from the collector, and is the
                internal address of your pganalyze Enterprise Server
                installation.
              </p>
              <p>
                <a href="https://pganalyze.com/docs/collector/settings">
                  See details on all collector configuration settings.
                </a>
              </p>
              <p>
                <strong>Network connectivity requirements</strong>
                <br />
                The collector requires outbound network access to both the
                pganalyze API (as specified by the <code>api_base_url</code>),
                and the object storage (Amazon S3 or Minio). Additionally it
                also requires connectivity to your database server.
              </p>
              <p>
                There are no connections initiated by the central pganalyze
                Enterprise Server container going into your database environment
                when using the separate collector installation, and no inbound
                ports need to be opened.
              </p>
            </PanelSection>
          )}
        </Panel>
      </div>
    </PageContent>
  );
};

export default ServerLocalCollectorNew;
