import React from "react";

import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";

import Grid from "components/Grid";
import ImpactRenderer, { Impact } from "components/ImpactRenderer";

import styles from "./style.module.scss";

import {
  GetIndexAdvisorIssues_getIssues as IssueType,
  GetIndexAdvisorIssues_getSchemaTables as SchemaTableType,
} from "../types/GetIndexAdvisorIssues";

import { getTableName } from "..";
import {
  formatApproximateNumber,
  formatBytes,
  formatNumber,
  formatNumberWithThreshold,
} from "utils/format";
import Popover from "components/Popover";
import { makeFilter } from "utils/filter";
import pluralize from "pluralize";

const MissingIndexList: React.FunctionComponent<{
  searchTerm: string;
  issues: IssueType[];
  tables: SchemaTableType[];
  databaseId: string;
}> = ({ searchTerm, issues, tables, databaseId }) => {
  const { databaseIssue } = useRoutes();
  const statsByTable = tables.reduce<{ [key: string]: SchemaTableType }>(
    (byTableMap, table) => {
      byTableMap[table.id] = table;
      return byTableMap;
    },
    {},
  );

  const gridData = issues
    .map((issue) => {
      const tableId = JSON.parse(issue.groupingKey).table;
      const tableName = getTableName(issue);
      const details = JSON.parse(issue.detailsJson);
      const improvement = details.aggregate_improvement;
      const tableSize = statsByTable[tableId]?.lastStats?.dataSizeBytes;
      const writeOverhead = details.index_write_overhead;
      const tableWritesPerMin = details.table_writes_per_min;

      return {
        id: issue.id,
        improvement,
        scans: details.scan_count,
        queries: details.query_count,
        tableName,
        tableSize,
        tableWritesPerMin,
        writeOverhead,
      };
    })
    .filter(makeFilter(searchTerm, "tableName"));

  return (
    <Grid
      className="grid-cols-[80px_3fr_100px_120px_150px_180px]"
      striped
      data={gridData}
      defaultSortBy="improvement"
      columns={[
        {
          header: "Impact",
          field: "improvement",
          className: "flex justify-center",
          defaultSortOrder: "desc",
          renderer: function Impact({ rowData: { id, scans }, fieldData }) {
            if (fieldData == null) {
              return (
                <Popover content="Could not calculate impact for this insight">
                  <Link
                    to={databaseIssue(
                      databaseId,
                      id,
                      "index_advisor/indexing_engine",
                    )}
                  >
                    <ImpactRenderer impact={undefined} />
                  </Link>
                </Popover>
              );
            }
            const roundedImprovement = formatApproximateNumber(fieldData);
            const impact = calculateImpact(fieldData);
            return (
              <Popover
                content={
                  <>
                    <div className={styles.costImprovement}>
                      <strong>{roundedImprovement}× cost improvement</strong>
                    </div>
                    <div>
                      This is a weighted average of the estimated cost
                      improvement across {pluralize("scan", scans, true)},
                      weighting by avg scans / minute
                    </div>
                  </>
                }
              >
                <Link
                  to={databaseIssue(
                    databaseId,
                    id,
                    "index_advisor/indexing_engine",
                  )}
                >
                  <ImpactRenderer impact={impact} />
                </Link>
              </Popover>
            );
          },
        },
        {
          header: "Table",
          field: "tableName",
          renderer: function IssueLinkCell({ rowData: { id }, fieldData }) {
            return (
              <Link
                to={databaseIssue(
                  databaseId,
                  id,
                  "index_advisor/indexing_engine",
                )}
              >
                {fieldData}
              </Link>
            );
          },
        },
        {
          header: "Queries",
          field: "queries",
          nullValue: "n/a",
          defaultSortOrder: "desc",
          style: "number",
        },
        {
          header: "Table Size",
          field: "tableSize",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          renderer: ({ fieldData }) => formatBytes(fieldData),
          style: "number",
        },
        {
          header: "Table Writes / Min",
          field: "tableWritesPerMin",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          renderer: ({ fieldData }) => formatNumberWithThreshold(fieldData, 3),
          style: "number",
        },
        {
          header: "Index Write Overhead",
          field: "writeOverhead",
          defaultSortOrder: "asc",
          nullValue: "n/a",
          renderer: function IndexWriteOverhead({ fieldData }) {
            return (
              <span className={styles.writeOverhead}>
                +{formatNumber(fieldData, 2)}
              </span>
            );
          },
          style: "number",
        },
      ]}
    />
  );
};

function calculateImpact(costImprovement: number): Impact {
  return costImprovement > 10_000
    ? 5
    : costImprovement > 1_000
    ? 4
    : costImprovement > 100
    ? 3
    : costImprovement > 10
    ? 2
    : 1;
}

export default MissingIndexList;
