import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { titleize } from "utils/inflection";
import { useRoutes } from "utils/routes";
import Loading from "components/Loading";
import LogLine from "../LogLine";

import {
  LogLineDetails as LogLineDetailsType,
  LogLineDetailsVariables,
} from "./types/LogLineDetails";
import { LogLines_getLogLines as LogLineType } from "../Container/types/LogLines";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";

type LogLineDetailValueType = string | number | boolean | React.ReactNode;

type LogLineDetailType = {
  [a: string]: LogLineDetailValueType;
};

type Props = {
  logLine: LogLineType;
  logLineId: string;
  serverId: string;
  databaseId?: string;
};

const LogLineDetails: React.FunctionComponent<Props> = ({
  logLine,
  logLineId,
  serverId: propServerId,
  databaseId: propDatabaseId,
}) => {
  const { serverLogs, serverRole, databaseLogs, databaseQuery } = useRoutes();
  const { data, loading, error } = useQuery<
    LogLineDetailsType,
    LogLineDetailsVariables
  >(QUERY, { variables: { logLineId, serverId: propServerId } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const {
    id,
    serverId,
    backendPid,
    logClassification,
    postgresRoleId,
    postgresRoleName,
  } = logLine;
  const { databaseId, queryId, details, childLines, childText, logText } =
    data.getLogLineDetails;
  const detailsParsed: LogLineDetailType =
    (details && JSON.parse(details)) || {};
  const permalinkPath =
    (propDatabaseId
      ? databaseLogs(propDatabaseId, logClassification)
      : serverLogs(serverId, logClassification)) +
    "#" +
    id;

  const sharedProps = {
    serverId: serverId,
    postgresRoleId: postgresRoleId,
    postgresRoleName: postgresRoleName,
    logClassification: logClassification,
    backendPid: backendPid,
  };

  return (
    <>
      <LogLine
        logLine={{ ...logLine, logText }}
        serverId={serverId}
        databaseId={databaseId}
      />
      {childLines?.map((cl) => (
        <div className={styles.logLineChild} key={cl.id}>
          <LogLine
            logLine={{ ...cl, ...sharedProps }}
            serverId={propServerId}
          />
        </div>
      ))}
      {childText?.split("\n")?.map((text, key) => (
        <div className={styles.logLineChild} key={key}>
          {text}
        </div>
      ))}
      <div className={styles.logLineDetails} key="details">
        <span>
          <strong>Permalink:</strong>{" "}
          <Link to={permalinkPath}>
            {window.location.origin + permalinkPath}
          </Link>
        </span>
        <br />
        <LogLineDetailItem
          name="Classification"
          value={titleize(logClassification.toLowerCase().replace(/_/g, " "))}
        />
        <LogLineDetailItem name="Backend PID" value={backendPid} />
        {postgresRoleId && (
          <LogLineDetailItem
            name="Role"
            value={
              <Link to={serverRole(serverId, postgresRoleId)}>
                {postgresRoleName}
              </Link>
            }
          />
        )}
        {databaseId && queryId && (
          <LogLineDetailItem
            name="Query"
            value={
              <Link to={databaseQuery(databaseId, queryId)}>
                {"#" + queryId}
              </Link>
            }
          />
        )}
        {detailsParsed && <br />}
        {Object.entries(detailsParsed).map(([k, v]) => {
          if (typeof v === "object") {
            v = JSON.stringify(v);
          }
          return (
            <LogLineDetailItem
              key={k}
              name={titleize(k.replace(/_/g, " "))}
              value={v}
            />
          );
        })}
      </div>
    </>
  );
};

const LogLineDetailItem: React.FunctionComponent<{
  name: string;
  value: LogLineDetailValueType;
}> = ({ name, value }) => {
  if (value === true) {
    value = "Yes";
  }
  if (value === false) {
    value = "No";
  }

  return (
    <span key={name}>
      <strong>{name}:</strong>
      &nbsp;
      {value}
    </span>
  );
};

export default LogLineDetails;
