import React from "react";
import moment from "moment-timezone";

import styles from "./style.module.scss";
import { InfoIcon, StatusDotIcon } from "components/Icons";
import Popover from "components/Popover";

type Props = {
  lastReceivedSnapshotAt: number | null | undefined;
  lastTestSnapshotAt: number | null | undefined;
  duplicateSnapshotReceivedAt: number | null | undefined;
};

class SnapshotStatus extends React.Component<Props> {
  render() {
    const {
      lastReceivedSnapshotAt,
      lastTestSnapshotAt,
      duplicateSnapshotReceivedAt,
    } = this.props;

    if (!lastReceivedSnapshotAt && !lastTestSnapshotAt) {
      return (
        <span>
          <StatusDotIcon status="no data" />
          <span>No Snapshots received</span>
        </span>
      );
    }

    const ts = moment(
      (lastReceivedSnapshotAt || lastTestSnapshotAt || 0) * 1000,
    );
    const dupTs = moment((duplicateSnapshotReceivedAt || 0) * 1000);
    const dupReceivedRecently = moment().diff(dupTs, "minutes") <= 30;
    const noRecentSnapshot = moment().diff(ts, "hours") > 1;
    const status = noRecentSnapshot || dupReceivedRecently ? "warning" : "okay";
    const statusTitle = dupReceivedRecently
      ? "Duplicate snapshot received"
      : noRecentSnapshot
      ? "Received last snapshot more than an hour ago"
      : "Recently received statistics data";

    return (
      <>
        <div>
          <span>
            {ts.fromNow()}
            <span className={styles.snapshotTimestamp}>
              {" "}
              · {ts.format("YYYY-MM-DD HH:mm:ss ZZ")}
            </span>
            {!lastReceivedSnapshotAt && (
              <span className={styles.snapshotTimestamp}> · Test Snapshot</span>
            )}
          </span>
        </div>
        <div>
          <StatusDotIcon status={status} title={statusTitle} /> {statusTitle}{" "}
          {dupReceivedRecently && (
            <Popover
              content={
                <>
                  Check if there are two collectors running at the same time to
                  monitor this sever. Contact us if you are not running two
                  collectors but seeing this message. (last received at{" "}
                  {dupTs.format("YYYY-MM-DD HH:mm:ss ZZ")})
                </>
              }
            >
              <InfoIcon />
            </Popover>
          )}
        </div>
      </>
    );
  }
}

export default SnapshotStatus;
