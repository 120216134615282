import Panel from "components/Panel";
import React from "react";

import {
  ExplainWorkspaceDetails_getExplainWorkspaceDetails as ExplainWorkspaceType,
  ExplainWorkspaceDetails_getExplainWorkspaceDetails_explainQueries as ExplainQueryType,
  ExplainWorkspaceDetails_getExplainWorkspaceDetails_explainQueries_explainResults as ExplainResultType,
} from "../ExplainWorkspace/types/ExplainWorkspaceDetails";
import { formatMs } from "utils/format";
import Grid, { MsCell, NumberCell } from "components/Grid";
import { faBell } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PanelSection from "components/PanelSection";
import ExpandableSQL from "components/ExpandableSQL";
import ExplainStatsAndOptions from "components/ExplainSidebar/ExplainStatsAndOptions";
import ExplainInsights from "components/ExplainSidebar/ExplainInsights";
import Identicon from "components/Identicon";
import { jsonParametersToString } from "../ExplainWorkspace/util";
import SQL from "components/SQL";
import Badge from "components/Badge";

const ExplainExperiment = ({
  workspace,
  explainQuery,
  blockSize,
}: {
  workspace: ExplainWorkspaceType;
  explainQuery: ExplainQueryType;
  blockSize: number;
}) => {
  return (
    <>
      <ExplainQueryPanel explainQuery={explainQuery} />
      <Panel title="Query Plans" secondaryTitle="Edit parameter set">
        <Grid
          className="grid-cols-[1fr_140px_120px_120px]"
          data={explainQuery.explainResults}
          pageSize={5}
          defaultSortBy="parameterSetId"
          columns={[
            {
              field: "parameterSetId",
              header: "Parameter Sets",
              nullValue: "-",
              renderer: function ParametersCell({ fieldData }) {
                const aliasParamMap = workspace.aliasParamMapList.find(
                  (val) => val.id === fieldData,
                );
                const jsonParams = jsonParametersToString(
                  aliasParamMap.parameters,
                  null,
                  Object.values(explainQuery.paramRefAliasMap),
                );
                return (
                  <div title={jsonParams}>
                    <SQL className="!whitespace-nowrap" sql={jsonParams} />
                  </div>
                );
              },
              disableSort: true,
              defaultSortOrder: "asc",
            },
            {
              field: "planFingerprint",
              header: "Plan Fingerprint",
              renderer: function PlanFingerprintCell({ fieldData }) {
                return (
                  <>
                    <Identicon identity={fieldData} />
                    <span title={fieldData}>{fieldData.substring(0, 7)}</span>
                  </>
                );
              },
            },
            {
              field: "runtimeMs",
              header: "Runtime",
              renderer: MsCell,
              style: "number",
            },
            {
              field: "totalCost",
              header: "Est. Cost",
              renderer: NumberCell,
              style: "number",
            },
          ]}
        />
      </Panel>
      <ExplainResultList
        explainResults={explainQuery.explainResults}
        blockSize={blockSize}
      />
    </>
  );
};

const ExplainQueryPanel = ({
  explainQuery,
}: {
  explainQuery: ExplainQueryType;
}) => {
  // TODO: update badges based on the query
  const badges = (
    <Badge className="ml-0">
      <FontAwesomeIcon icon={faBell} /> NEW SLOW QUERY
    </Badge>
  );
  const runtimeValues = explainQuery.explainResults.map((val) => val.runtimeMs);
  return (
    <div className="rounded-md bg-[#f7fafc] grid grid-cols-2 p-4 mb-4 gap-2">
      <div className="col-span-2">{badges}</div>
      <div className="col-span-2 text-[18px] leading-6">
        {explainQuery.name}
      </div>
      <div className="col-span-2">
        <ExpandableSQL sql={explainQuery.queryTextWithAlias} />
      </div>
      <div>
        <div className="text-[11px]">MIN RUNTIME</div>
        <div className="text-[16px]">
          {formatMs(Math.min(...runtimeValues))}
        </div>
      </div>
      <div>
        <div className="text-[11px]">MAX RUNTIME</div>
        <div className="text-[16px]">
          {formatMs(Math.max(...runtimeValues))}
        </div>
      </div>
    </div>
  );
};

const ExplainResultList = ({
  explainResults,
  blockSize,
}: {
  explainResults: ExplainResultType[];
  blockSize: number;
}) => {
  // TODO: not following design mock yet
  return explainResults.map((result) => {
    const title = (
      <div className="flex items-center">
        <Identicon
          identity={result.planFingerprint}
          className="!w-[18px] !h-[18px]"
        />
        <span title={result.planFingerprint}>
          {result.planFingerprint.substring(0, 7)}
        </span>
      </div>
    );
    const plan = JSON.parse(result.annotatedJson);
    return (
      <Panel title={title} key={result.id}>
        <PanelSection>
          <div className="flex">
            <ExplainStatsAndOptions
              blockSize={blockSize}
              fingerprint={result.planFingerprint}
              totCost={result.totalCost}
              ioBlocks={result.totalSharedBlksRead}
              ioMs={result.totalBlkReadTime}
              plan={plan.plan}
              runtime={result.runtimeMs}
            />
            <ExplainInsights annotations={plan.annotations} />
          </div>
        </PanelSection>
      </Panel>
    );
  });
};

export default ExplainExperiment;
