import React from "react";
import { useParams } from "react-router-dom";
import { useRoutes } from "utils/routes";

import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import { useFeature } from "components/OrganizationFeatures";
import ExplainList from "components/ExplainList";
import ExplainWorkspaceList from "components/ExplainWorkspaceList";
import ServerExplainWorkspaceList from "components/ServerExplainWorkspaceList";
import PageContent from "components/PageContent";

type ExplainOverviewTab = "overview" | "workspace";

const ExplainOverview: React.FunctionComponent<{ tab: ExplainOverviewTab }> = ({
  tab,
}) => {
  const { databaseId, serverId } = useParams();
  const {
    databaseQueriesExplains,
    serverQueriesExplains,
    databaseQueriesExplainsWorkspaces,
    serverQueriesExplainsWorkspaces,
  } = useRoutes();
  const explainUpload = useFeature("explainUpload");

  const featureNav = databaseId ? (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseQueriesExplains(databaseId)}>
        Overview
      </PageNavLink>
      <PageNavLink to={databaseQueriesExplainsWorkspaces(databaseId)}>
        Workspaces
      </PageNavLink>
    </PageSecondaryNavigation>
  ) : (
    <PageSecondaryNavigation>
      <PageNavLink to={serverQueriesExplains(serverId)}>Overview</PageNavLink>
      <PageNavLink to={serverQueriesExplainsWorkspaces(serverId)}>
        Workspaces
      </PageNavLink>
    </PageSecondaryNavigation>
  );

  return (
    <PageContent
      title="EXPLAIN Plans"
      pageCategory="queries"
      pageName="explains"
      featureNav={explainUpload && featureNav}
    >
      <ExplainOverviewContent tab={tab} databaseId={databaseId} />
    </PageContent>
  );
};

const ExplainOverviewContent: React.FunctionComponent<{
  tab: ExplainOverviewTab;
  databaseId: string;
}> = ({ tab, databaseId }) => {
  if (tab == "workspace") {
    if (databaseId) {
      return <ExplainWorkspaceList />;
    } else {
      return <ServerExplainWorkspaceList />;
    }
  }

  return <ExplainList />;
};

export default ExplainOverview;
