---
title: 'Step 2: Create Monitoring User'
install_track_title: Installation Guide (Azure Database for PostgreSQL)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import MonitoringUserSetupInstructions from "../../components/MonitoringUserSetupInstructions";
import VerifyConnection from "../../components/VerifyConnection";

We recommend creating a separate monitoring user on your PostgreSQL database for pganalyze.

As a user with **azure_pg_admin privileges**, connect to the database you will be monitoring
and run the following to create a new user (we've generated a random password for you, but you
can replace it with one of your choosing):

<MonitoringUserSetupInstructions />

Note that it is important you run these as the Postgres superuser in order to pass down the full access to statistics tables.

## Verify connection

Let's make sure we can connect to the database with the new user:

<VerifyConnection host="mydb.postgres.database.azure.com" />

Write down the username and password of the monitoring user, we will use it in the last step of this tutorial.

---

Next we continue by installing the pganalyze collector:

<Link className="btn btn-success" to="03_install_the_collector">
  Proceed to Step 3: Install the collector
</Link>
