import React from "react";

import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import Panel from "components/Panel";

import { useRoutes } from "utils/routes";
import { Link, useParams } from "react-router-dom";
import Grid from "components/Grid";
import {
  ExplainWorkspacesForServer as ExplainWorkspacesType,
  ExplainWorkspacesForServerVariables,
} from "./types/ExplainWorkspacesForServer";

import QUERY from "./Query.graphql";

const ServerExplainWorkspaceList: React.FunctionComponent = () => {
  const { databaseQueriesExplainsWorkspaces } = useRoutes();
  const { serverId } = useParams();
  const { loading, error, data } = useQuery<
    ExplainWorkspacesType,
    ExplainWorkspacesForServerVariables
  >(QUERY, {
    variables: { serverId },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const workspaceCountByDatabase = data.getExplainWorkspaces.reduce(
    (acc, workspace) => {
      const databaseId = workspace.database.id;
      acc[databaseId] = (acc[databaseId] || 0) + 1;
      return acc;
    },
    {} as Record<string, number>,
  );

  const databases = data.getDatabases
    .filter((database) => !database.hidden)
    .map((database) => {
      return {
        id: database.id,
        datname: database.datname,
        workspaceCount: workspaceCountByDatabase[database.id],
      };
    });

  return (
    <Panel title="Databases">
      <Grid
        className="grid-cols-[1fr_150px]"
        data={databases}
        striped
        columns={[
          {
            field: "datname",
            header: "Name",
            renderer: function DatabaseCell({ rowData, fieldData }) {
              return (
                <Link to={databaseQueriesExplainsWorkspaces(rowData.id)}>
                  {fieldData}
                </Link>
              );
            },
          },
          {
            field: "workspaceCount",
            header: "Workspaces",
            style: "number",
            nullValue: 0,
          },
        ]}
      />
    </Panel>
  );
};

export default ServerExplainWorkspaceList;
