import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Plan, Node } from "types/explain";

type PlanContext = {
  selectedNodeId: number | string;
  setSelectedNodeId: (newId: number | string) => void;
  selectedNode: Node | undefined;
};

const SelectedNodeContext = React.createContext<PlanContext | undefined>(
  undefined,
);

const WithNodeSelection = ({
  children,
  plan,
}: {
  plan: Plan;
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const hashNodeMatch = hash.length > 0 && hash.match(/^#node-(.+)$/);
  const match = hashNodeMatch && hashNodeMatch[1];
  const number = Number(match);
  const currHashNode = Number.isNaN(number) ? match : number;

  const [selectedNodeId, setSelectedNodeId] = useState(currHashNode);
  useEffect(() => {
    if (currHashNode && currHashNode != selectedNodeId) {
      setSelectedNodeId(currHashNode);
    }
  }, [currHashNode, selectedNodeId]);
  const hashAwareSetter = (nodeId: number | string): void => {
    navigate(`#node-${nodeId}`);
  };
  const currentValue = {
    selectedNodeId,
    setSelectedNodeId: hashAwareSetter,
    selectedNode: findNode(plan, selectedNodeId),
  };
  return (
    <SelectedNodeContext.Provider value={currentValue}>
      {children}
    </SelectedNodeContext.Provider>
  );
};

export function useSelectedNode() {
  const { selectedNode, setSelectedNodeId } = useContext(SelectedNodeContext);

  return [selectedNode, setSelectedNodeId] as const;
}

const findNode: (plan: Plan, nodeId: number | string) => Node | undefined = (
  plan,
  nodeId,
) => {
  let result: Node;

  function doFindNode(node: Node) {
    if (node.extra.id === nodeId || node["Subplan Name"] == `CTE ${nodeId}`) {
      result = node;
      return;
    }
    if (node.Plans) {
      for (let i = 0; !result && i < node.Plans.length; i++) {
        doFindNode(node.Plans[i]);
      }
    }
  }

  for (let i = 0; !result && i < plan.length; i++) {
    doFindNode(plan[i].Plan);
  }
  return result;
};

export default WithNodeSelection;
