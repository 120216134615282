import React from "react";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";
import {
  SchemaTableColumns as SchemaTableColumnsType,
  SchemaTableColumns_getSchemaTableDetails_lastInfo as SchemaTableInfoType,
  SchemaTableColumns_getSchemaTableDetails as SchemaTableDetailsType,
  SchemaTableColumnsVariables,
} from "./types/SchemaTableColumns";
import QUERY from "./Query.graphql";
import ShowFlash from "components/ShowFlash";
import {
  formatBytes,
  formatNumber,
  formatNumberWithThreshold,
  formatPercent,
  formatTimestampLong,
} from "utils/format";
import IssueSummaryBadge from "components/VacuumAdvisor/IssueSummaryBadge";
import { useDateRange } from "components/WithDateRange";
import Grid, { GridColumn, NumberCell } from "components/Grid";
import ExtendedStatsPanel from "./ExtendedStatsPanel";
import moment, { Moment } from "moment";

type Props = {
  databaseId: string;
  tableId: string;
  liveTuples?: number;
};

const SchemaTableColumns: React.FunctionComponent<Props> = ({
  databaseId,
  tableId,
  liveTuples,
}) => {
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;
  const { data, loading, error } = useQuery<
    SchemaTableColumnsType,
    SchemaTableColumnsVariables
  >(QUERY, {
    variables: {
      databaseId,
      tableId,
      startTs: newStartTs.unix(),
      endTs: newEndTs.unix(),
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  return (
    <>
      <RowStatsPanel data={data.getSchemaTableDetails} />
      <ColumnsPanel liveTuples={liveTuples} data={data} />
      <ExtendedStatsPanel databaseId={databaseId} tableId={tableId} />
    </>
  );
};

const RowStatsPanel: React.FunctionComponent<{
  data: SchemaTableDetailsType;
}> = ({ data }) => {
  const avgRowSize = data.estimatedRowSize;
  const lastAnalyzeAt = getLastAnalyzeAt(data.lastInfo);

  return (
    <Panel title="Row Statistics">
      <PanelTable horizontal borders>
        <tbody>
          <tr>
            <th>Average Row Size</th>
            <td>{avgRowSize == null ? "n/a" : formatBytes(avgRowSize)}</td>
            <th>Last Analyzed</th>
            <td>
              {lastAnalyzeAt == null
                ? "-"
                : `${formatTimestampLong(
                    lastAnalyzeAt,
                  )} · ${lastAnalyzeAt.fromNow()}`}
            </td>
          </tr>
        </tbody>
      </PanelTable>
    </Panel>
  );
};

const ColumnsPanel: React.FunctionComponent<{
  data: SchemaTableColumnsType;
  liveTuples: number;
}> = ({ data, liveTuples }) => {
  const columns = data.getSchemaTableColumns.map((c) => ({
    name: c.name,
    dataType: c.dataType,
    definition: `${c.notNull ? "NOT NULL " : ``}${
      c.defaultValue ? `DEFAULT ${c.defaultValue}` : ``
    }`,
    nullFrac: c.lastStats?.nullFrac,
    avgWidth: c.lastStats?.avgWidth,
    nDistinct: c.lastStats?.nDistinct,
    inhNullFrac: c.lastStatsInherited?.nullFrac,
    inhAvgWidth: c.lastStatsInherited?.avgWidth,
    inhNDistinct: c.lastStatsInherited?.nDistinct,
    updatesPerMinute: c.updatesPerMinute,
    indexed: c.indexed,
    hotUpdateCapable: c.updatesPerMinute != null ? c.hotUpdateCapable : null,
  }));
  const hasColumnStats = data.getDatabaseDetails.hasColumnStats;
  const hasInheritedColStats = data.getSchemaTableColumns.some(
    (col) => !!col.lastStatsInherited,
  );

  const baseColumnsStart: GridColumn<
    (typeof columns)[number],
    keyof (typeof columns)[number]
  >[] = [
    {
      field: "name",
      header: "Name",
      title: true,
    },
    {
      field: "dataType",
      header: "Type",
      nullValue: "Unknown",
      title: true,
    },
  ];

  const baseColumnsEnd: GridColumn<
    (typeof columns)[number],
    keyof (typeof columns)[number]
  >[] = [
    {
      field: "updatesPerMinute",
      header: "Updates / Min",
      tip: "How often this column is updated per minute on average, based on UPDATE statements and CTEs containing UPDATEs on this table. If multiple statements update this column, this is the sum of the average number of calls. Excludes any queries for which query analysis failed (see Index Advisor status).",
      style: "number",
      nullValue: "-",
      renderer: ({ fieldData }) => formatNumberWithThreshold(fieldData, 2),
    },
    {
      field: "indexed",
      header: "Indexed?",
      tip: "Indicates whether there are any indexes that include this column. This also considers indexes that have expressions referencing the column, as well as partial and covering indexes.",
      style: "number",
      renderer: ({ fieldData }) => (fieldData ? "Yes" : "No"),
    },
    {
      field: "hotUpdateCapable",
      header: "HOT?",
      tip: "Indicates whether updates on this column (if any) can create Heap Only Tuples (HOT). Generally, updates are not HOT capable when the column is indexed. For best performance and to reduce VACUUM overhead, avoid creating indexes on columns that are updated frequently.",
      style: "number",
      nullValue: "-",
      renderer: ({ fieldData }) =>
        fieldData ? (
          <>
            Yes <IssueSummaryBadge severity={undefined} />
          </>
        ) : (
          <>
            No <IssueSummaryBadge severity="critical" />
          </>
        ),
    },
    {
      field: "definition",
      header: "Modifiers",
      nullValue: "Unknown",
      style: "query",
    },
  ];

  const statsColumns: GridColumn<
    (typeof columns)[number],
    keyof (typeof columns)[number]
  >[] = [
    {
      field: "nullFrac",
      header: "Null %",
      tip: "Fraction of the rows in this table that have a NULL value in this column.",
      style: "number",
      nullValue: "n/a",
      renderer: ({ fieldData }) => formatPercent(fieldData, 2),
    },
    {
      field: "avgWidth",
      header: "Avg. Size",
      tip: "Average size, in bytes, of the values in this column across all the rows in this table (excluding NULLs).",
      style: "number",
      nullValue: "n/a",
      renderer: NumberCell,
    },
    {
      field: "nDistinct",
      header: "Num. Distinct",
      tip: `Estimated number of distinct values in this column. If followed by an asterisk (*), the number of values is expected to grow as the table grows (e.g., for a unique value like a primary key).${
        liveTuples != null
          ? ` The current row count is estimated to be ${formatNumber(
              liveTuples,
            )}.`
          : ``
      }`,
      style: "number",
      nullValue: "n/a",
      renderer: ({ fieldData }) => formatNDistinct(fieldData, liveTuples),
    },
  ];

  const inheritedStatsColumns: GridColumn<
    (typeof columns)[number],
    keyof (typeof columns)[number]
  >[] = [
    {
      field: "inhNullFrac",
      header: "Full Null %",
      tip: "Fraction of the rows in this table and all child tables that have a NULL value in this column.",
      style: "number",
      nullValue: "n/a",
      renderer: ({ fieldData }) => formatPercent(fieldData, 2),
    },
    {
      field: "inhAvgWidth",
      header: "Full Avg. Size",
      tip: "Average size, in bytes, of the values in this column across all the rows in this table and all child tables (excluding NULLs).",
      style: "number",
      nullValue: "n/a",
      renderer: NumberCell,
    },
    {
      field: "inhNDistinct",
      header: "Num. Distinct",
      tip: `Estimated number of distinct values in this column in this table and all child tables. If followed by an asterisk (*), the number of values is expected to grow as the table grows (e.g., for a unique value like a primary key).${
        liveTuples != null
          ? ` The current row count is estimated to be ${formatNumber(
              liveTuples,
            )}.`
          : ``
      }`,
      style: "number",
      nullValue: "n/a",
      renderer: ({ fieldData }) => formatNDistinct(fieldData, liveTuples),
    },
  ];

  return (
    <Panel title="Columns">
      {!hasColumnStats && (
        <ShowFlash
          level="notice"
          msg={
            <>
              Your database appears to be missing column stats monitoring helper
              functions, so column statistics are not available and average row
              size is based on a very rough estimate. Please review the relevant{" "}
              <a
                target="_blank"
                href="https://pganalyze.com/docs/install/troubleshooting/column_stats_helper"
              >
                troubleshooting documentation
              </a>
              .
            </>
          }
        />
      )}
      {hasInheritedColStats ? (
        <Grid
          // name, type, null %, avg size, num distinct, full null %, full avg size, full num distinct, updates/min, indexed, hot, definition
          className={`grid-cols-[minmax(0,_2fr)_repeat(3,_minmax(0,_1fr))_repeat(4,_minmax(0,_1.1fr))_minmax(0,_1.1fr)_minmax(0,_1fr)_minmax(0,_0.75fr)_minmax(0,_3fr)]`}
          data={columns}
          columns={[
            ...baseColumnsStart,
            ...statsColumns,
            ...inheritedStatsColumns,
            ...baseColumnsEnd,
          ]}
        />
      ) : hasColumnStats ? (
        <Grid
          // name, type, null %, avg size, num distinct, updates/min, indexed, hot, definition
          className={`grid-cols-[minmax(0,_2fr)_repeat(3,_minmax(0,_1fr))_repeat(2,_minmax(0,_1.3fr))_minmax(0,_1fr)_minmax(0,_0.75fr)_minmax(0,_3fr)]`}
          data={columns}
          columns={[...baseColumnsStart, ...statsColumns, ...baseColumnsEnd]}
        />
      ) : (
        <Grid
          // name, type, updates/min, indexed, hot, definition
          className={`grid-cols-[minmax(0,_2fr)_minmax(0,_1fr)_minmax(0,_1.3fr)_minmax(0,_1fr)_minmax(0,_0.75fr)_minmax(0,_4fr)]`}
          data={columns}
          columns={[...baseColumnsStart, ...baseColumnsEnd]}
        />
      )}
    </Panel>
  );
};

function getLastAnalyzeAt(
  tableInfo: SchemaTableInfoType | undefined,
): Moment | undefined {
  if (!tableInfo) {
    return undefined;
  }
  const { lastAnalyzeAt, lastAutoanalyzeAt } = tableInfo;
  const lastEitherAnalyzeAt =
    lastAnalyzeAt == null
      ? lastAutoanalyzeAt
      : lastAutoanalyzeAt == null
      ? lastAnalyzeAt
      : Math.max(lastAnalyzeAt, lastAutoanalyzeAt);

  if (lastEitherAnalyzeAt == null) {
    return undefined;
  }

  return moment.unix(lastEitherAnalyzeAt);
}

function formatNDistinct(value: number, liveTuples: number | undefined) {
  if (value >= 0) {
    // add a space for alignment with the negative nDistinct variant
    return <>{formatNumber(value)}&nbsp;</>;
  }

  if (liveTuples == undefined) {
    // Since we don't know the total row count, we can't express nDistinct as a
    // row count. We could show the raw ratio, but since that might be confusing
    // and since we don't expect to have nDistinct stats without having row
    // count stats, just show "?" (and a space for alignment)
    return <>?&nbsp;</>;
  }

  const distinctRatio = Math.abs(value);
  const distinctCount = distinctRatio * liveTuples;
  return formatNumber(distinctCount) + "*";
}

export default SchemaTableColumns;
