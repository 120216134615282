---
title: Get Started
---

import PublicOverview from './_install_public.mdx'
import OverviewInstallChoice from './_install_choice.mdx'

<PublicOnly>
  <PublicOverview />
</PublicOnly>

To continue please select in which environment you are running your Postgres database:

<OverviewInstallChoice />

<PublicOnly>
  <div>
    <br />
    Additional installation notes:
    <ul>
      <li>
        <Link to="install/01_enabling_pg_stat_statements">Enable pg_stat_statements</Link>
      </li>
      <li>
        <Link to="install/amazon_rds/iam_policy">Amazon RDS and Aurora: IAM Policy</Link>
      </li>
    </ul>
  </div>
</PublicOnly>
