import React, { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";
import Loading from "components/Loading";
import LogAnalysis from "components/LogAnalysis";
import LogLines from "components/LogLines";
import LogStats from "components/LogStats";
import Panel from "components/Panel";
import LogProcessingDisabledPanel from "components/LogProcessingDisabledPanel";
import LogLinesTimeSelector from "./LogLinesTimeSelector";
import IntegrationHint from "./IntegrationHint";

import { formatTimestampShort } from "utils/format";
import { useDateRange } from "components/WithDateRange";

import directory from "../../../../docs/directory.json";

import {
  LogLinesAndStats as LogLinesAndStatsType,
  LogLinesAndStatsVariables,
} from "./types/LogLinesAndStats";

import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import PanelSection from "components/PanelSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchMinus } from "@fortawesome/pro-solid-svg-icons";
import { ExchangeIcon } from "components/Icons";

type Props = {
  databaseId?: string;
  serverId?: string;
  activeClassification: string | null | undefined;
};

const LogLinesAndStats: React.FunctionComponent<Props> = ({
  databaseId,
  serverId,
  activeClassification,
}) => {
  const { data, loading, error } = useQuery<
    LogLinesAndStatsType,
    LogLinesAndStatsVariables
  >(QUERY, {
    variables: {
      databaseId,
      serverId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return (
    <LogLinesAndStatsDisplay
      databaseId={databaseId}
      serverId={serverId}
      activeClassification={activeClassification}
      data={data}
    />
  );
};

type DisplayProps = Props & {
  data: LogLinesAndStatsType;
};

const LogLinesAndStatsDisplay: React.FunctionComponent<DisplayProps> = ({
  activeClassification,
  data,
  databaseId,
}) => {
  const { hash, search } = useLocation();
  const timeOverride = useMemo<moment.Moment | null>(() => {
    if (!hash) {
      return null;
    }
    const id = hash.substring(1);
    if (!id.startsWith("t=")) {
      return null;
    }

    return moment.unix(parseInt(id.substring(2)));
  }, [hash]);
  const navigate = useNavigate();

  let classification = activeClassification;
  const classificationDetails = directory.logClassifications[classification];
  if (!classificationDetails) {
    classification = null;
  }

  const {
    integratedLogInsights,
    humanId,
    usesLocalCollector,
    enableLogs,
    systemType,
    collectorInfo,
  } = data.getServerDetails;

  const handleLineFocused = (id: string | null | undefined) => {
    navigate({ hash: id, search });
  };

  const handleTimeSelected = (time: moment.Moment | null | undefined) => {
    navigate({
      hash: `t=${time.unix()}`,
      search,
    });
  };

  return (
    <>
      {collectorInfo?.logCollectionDisabled && (
        <LogProcessingDisabledPanel
          disabledReasons={collectorInfo.logCollectionDisabledReason}
        />
      )}
      <Panel title="Postgres Server Log">
        {integratedLogInsights && (
          <LogInsightsContent
            classification={classification}
            serverId={data.getServerDetails.humanId}
            databaseId={databaseId}
            timeOverride={timeOverride}
            onLineFocused={handleLineFocused}
            onTimeSelected={handleTimeSelected}
          />
        )}
        {!integratedLogInsights && (
          <IntegrationHint
            serverId={humanId}
            usesLocalCollector={usesLocalCollector}
            enableLogs={enableLogs}
            systemType={systemType}
          />
        )}
      </Panel>
    </>
  );
};

const LogInsightsContent: React.FunctionComponent<{
  classification: string | null | undefined;
  databaseId: string;
  serverId: string;
  timeOverride: moment.Moment | null | undefined;
  onLineFocused: (id: string | null | undefined) => void;
  onTimeSelected: (time: moment.Moment | null | undefined) => void;
}> = ({
  classification,
  databaseId,
  serverId,
  timeOverride,
  onLineFocused,
  onTimeSelected,
}) => {
  const { databaseLogs, serverLogs } = useRoutes();
  const [ready, setReady] = useState(false);
  const [{ from, to }] = useDateRange();
  const handleLogsReady = () => {
    setReady(true);
  };
  const validTimeOverride =
    !timeOverride ||
    (timeOverride.isSameOrAfter(from) && timeOverride.isSameOrBefore(to));
  return (
    <div>
      {classification && (
        <PanelSection>
          <Link
            to={databaseId ? databaseLogs(databaseId) : serverLogs(serverId)}
          >
            <FontAwesomeIcon icon={faSearchMinus} /> Show all log events
          </Link>
        </PanelSection>
      )}
      <LogStats
        serverId={serverId}
        databaseId={databaseId}
        selectedClassification={classification}
        highlightedTime={timeOverride}
      />
      {classification && (
        <LogAnalysis
          serverId={serverId}
          databaseId={databaseId}
          classification={classification}
          highlightedTime={timeOverride}
        />
      )}
      {ready && (
        <LogLinesTimeSelector
          selectedTime={timeOverride}
          onTimeSelected={onTimeSelected}
          labelPosition={classification ? "top" : "left"}
        />
      )}
      {timeOverride && ready && (
        <div className={styles.timeOverrideHint}>
          Showing last 100 log entries up to{" "}
          {formatTimestampShort(timeOverride)}.
        </div>
      )}
      {validTimeOverride ? (
        <LogLines
          serverId={serverId}
          databaseId={databaseId}
          classification={classification}
          hideIfEmpty
          focusLineHandler={onLineFocused}
          occurredBefore={timeOverride && timeOverride.unix()}
          onReady={handleLogsReady}
        />
      ) : (
        <InvalidTimeOverride onReset={() => onTimeSelected(to)} />
      )}
    </div>
  );
};

const InvalidTimeOverride: React.FunctionComponent<{ onReset: () => void }> = ({
  onReset,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    onReset();
  };
  return (
    <div className={styles.invalidTimeOverride}>
      <ExchangeIcon />
      Log timestamp is outside of the selected time range.{" "}
      <a href="" onClick={handleClick}>
        Show logs from end of time range
      </a>
    </div>
  );
};

export default LogLinesAndStats;
