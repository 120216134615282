import classNames from "classnames";
import Button from "components/Button";
import React from "react";

type ButtonBarOpt<T extends string> = {
  label: string;
  value: T;
};

function PillButtonBar<T extends string>({
  opts,
  selected,
  onChange,
}: {
  opts: ButtonBarOpt<T>[];
  selected: T;
  onChange: (value: T) => void;
}) {
  return (
    <div className="flex gap-4">
      {opts.map((opt) => {
        return (
          <div
            key={opt.value}
            className={classNames(
              "whitespace-nowrap rounded-md font-medium",
              opt.value === selected
                ? "bg-[#AAECA8] text-[#19152C] hover:bg-[#B8EAAE]"
                : "bg-transparent text-[#606060] hover:text-[#19152C]",
            )}
          >
            <Button
              className="rounded-full !py-1 !px-4"
              bare
              onClick={() => onChange(opt.value)}
            >
              {opt.label}
            </Button>
          </div>
        );
      })}
    </div>
  );
}

export default PillButtonBar;
