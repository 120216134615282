import React, { useMemo, useState } from "react";

import classNames from "classnames";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import ExplainDetails from "components/ExplainDetails";
import ExplainSidebar from "components/ExplainSidebar";
import ExpandableSQL from "components/ExpandableSQL";
import WithNodeSelection from "components/Explain/WithNodeSelection";
import PillButtonBar from "components/PillButtonBar";
import ExplainComparison from "./ExplainComparison";
import ExplainViewSource from "./ExplainViewSource";
import WithExplainDisplayOptions from "./WithExplainDisplayOptions";
import type { ExplainPlanType, QuerySampleType } from "components/Explain/util";

import styles from "./style.module.scss";

type Props = {
  explain: ExplainPlanType;
  databaseId: string;
  blockSize: number;
};

const ExplainPanel: React.FunctionComponent<Props> = ({
  explain,
  databaseId,
  blockSize,
}) => {
  const [section, setSection] = useState<
    "viz" | "text" | "json" | "comparison"
  >("viz");

  const plan = useMemo(() => {
    return JSON.parse(explain.annotatedJson);
  }, [explain.annotatedJson]);

  return (
    <div className="px-[20px] flex-grow h-min overflow-auto">
      <div className="h-full flex">
        <WithNodeSelection plan={plan.plan}>
          <WithExplainDisplayOptions>
            <div className="flex-grow relative overflow-y-scroll pl-1 -ml-1 pr-4">
              <div
                className={classNames(
                  "py-4 mb-1 flex gap-5 sticky top-0 z-50 px-1 -mx-1",
                  styles.buttonBar,
                )}
              >
                <PillButtonBar
                  opts={[
                    { value: "viz", label: "Node Tree" },
                    { value: "text", label: "Text" },
                    { value: "json", label: "JSON" },
                    { value: "comparison", label: "Compare Plans" },
                  ]}
                  selected={section}
                  onChange={(value) => setSection(value)}
                />
              </div>
              <QueryPanel querySample={explain.querySample} />
              {section === "comparison" ? (
                <Panel title="Plan Comparison">
                  <PanelSection>
                    <ExplainComparison
                      databaseId={databaseId}
                      blockSize={blockSize}
                      plan={plan}
                      explain={explain}
                    />
                  </PanelSection>
                </Panel>
              ) : section === "json" || section === "text" ? (
                <div className="mt-8">
                  <ExplainViewSource
                    format={section}
                    annotatedPlan={plan}
                    textPlan={explain.outputText}
                  />
                </div>
              ) : section === "viz" ? (
                <ExplainDetails plan={plan} databaseId={databaseId} />
              ) : null}
            </div>
            {/* N.B.: pb is to clear the Get Help button in the lower right corner */}
            {section !== "comparison" && (
              <div className="p-4 pb-[80px] border-l w-[480px] -mr-4 shrink-0 overflow-y-scroll">
                <ExplainSidebar
                  explain={explain}
                  blockSize={blockSize}
                  plan={plan}
                  databaseId={databaseId}
                  summaryOnly={section === "json" || section === "text"}
                />
              </div>
            )}
          </WithExplainDisplayOptions>
        </WithNodeSelection>
      </div>
    </div>
  );
};

const QueryPanel = ({ querySample }: { querySample: QuerySampleType }) => {
  if (!querySample) {
    return null;
  }
  const parameters = querySample.parameters;
  return (
    <Panel title="SQL Statement">
      <PanelSection>
        <ExpandableSQL sql={querySample.queryText} />
      </PanelSection>
      {parameters && parameters.length > 0 && (
        <PanelSection>
          <pre className={styles.noborder}>
            {parameters
              .map(
                (p: string, index: number): React.ReactNode =>
                  `$${index + 1} = '${p}'`,
              )
              .join(", ")}
          </pre>
        </PanelSection>
      )}
    </Panel>
  );
};

export default ExplainPanel;
