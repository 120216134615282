import React from "react";

import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "components/Button";
import { ModalContent, ModalOverlay } from "components/Modal";

const ModalContainer = ({
  title,
  onClose,
  className,
  children,
}: {
  title?: React.ReactNode;
  className?: string;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  const iconClickHandler = () => {
    onClose();
  };
  return (
    <ModalOverlay className="bg-[#000000AF] z-[100]" onDismiss={onClose}>
      <ModalContent className={className}>
        <div className="flex space-between bg-[#f7fafc] text-[#606060] border-b border-solid border-[#e8e8ee] text-lg leading-9 pl-4">
          <span className="w-full inline-flex items-center overflow-hidden text-ellipsis">
            {title}
          </span>
          <Button
            className="float-right text-lg !p-3"
            bare
            onClick={iconClickHandler}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <div className="m-5 mb-24">{children}</div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalContainer;
