import React from "react";
import { graphql } from "@apollo/client/react/hoc";

import flowRight from "lodash/flowRight";

import { decrypt, BLOCK_SIZE } from "utils/s3Decryption";

import { LogLineType } from "types/LogLineType";
import LogLineContent from "../LogLineContent";
import QUERY from "./Query.graphql";

type Props = {
  logLine: LogLineType;
  cipherText: ArrayBuffer;
  cipherKey: string;
  cipherIv: string;
  data: {
    getLogEncryptionKey: {
      plaintext: string;
    };
  };
  serverId: string;
};

type State = {
  content: string | null | undefined;
};

class EncryptedLogLine extends React.Component<Props, State> {
  _isMounted: boolean;

  constructor(props: Props) {
    super(props);
    this.state = {
      content: null,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    const rangeLower = this.props.logLine.byteContentStart;
    const rangeUpper = this.props.logLine.byteRangeEnd;
    const additionalStartBytes = rangeLower % BLOCK_SIZE;
    const key = this.props.data.getLogEncryptionKey;

    decrypt(
      new Uint8Array(this.props.cipherText),
      key.plaintext,
      this.props.cipherIv,
      rangeLower - additionalStartBytes,
    )
      .then((data: string) => {
        if (!this._isMounted) {
          return;
        }
        this.setState({
          content: data.slice(
            additionalStartBytes,
            rangeUpper - rangeLower + additionalStartBytes,
          ),
        });
      })
      .catch((err: string) => {
        console.error(err);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.state.content) {
      return "Decrypting...";
    }

    return (
      <LogLineContent
        content={this.state.content}
        logLine={this.props.logLine}
        serverId={this.props.serverId}
      />
    );
  }
}

class EncryptedLogLineContainer extends React.Component<Props> {
  render() {
    if (!this.props.data.getLogEncryptionKey) {
      return "Decrypting...";
    }

    return <EncryptedLogLine {...this.props} />;
  }
}

type QueryProps = {
  cipherKey: string;
  keyId: string;
  logLine: LogLineType;
  serverId: string;
};

type QueryOptsType = any;

export default flowRight(
  graphql(QUERY, {
    options: ({
      cipherKey,
      keyId,
      logLine,
      serverId,
    }: QueryProps): QueryOptsType => ({
      variables: {
        cipherKey,
        keyId: keyId,
        serverId: serverId,
        databaseId: logLine.databaseId,
      },
    }),
  }),
)(EncryptedLogLineContainer);
