// Notes:
// *Safari doesn't support AES-CTR in the vendor prefixed webkitSubtle module
// * IE11 might be able to support things with the msCrypto prefix, but it hasn't been tested

// Part of this code is based on the AWS Java client library
// (c) Amazon Web Services (licensed under Apache 2.0)

// Avoid re-importing keys if we already know them
const KEY_CACHE = {};

/**
 * The maximum number of 16-byte blocks that can be encrypted with a
 * GCM cipher.  Note the maximum bit-length of the plaintext is (2^39 - 256),
 * which translates to a maximum byte-length of (2^36 - 32), which in turn
 * translates to a maximum block-length of (2^32 - 2).
 * <p>
 * Reference: <a href="http://csrc.nist.gov/publications/nistpubs/800-38D/SP-800-38D.pdf">
 * NIST Special Publication 800-38D.</a>.
 */
const MAX_GCM_BLOCKS = 4294967294; // 2^32 - 2

export const BLOCK_SIZE = 16;

/**
 * Increment the rightmost 32 bits of a 16-byte counter by the specified
 * delta. Both the specified delta and the resultant value must stay within
 * the capacity of 32 bits.
 * (Package private for testing purposes.)
 *
 * @param counter
 *            a 16-byte counter used in AES/CTR
 * @param blockDelta
 *            the number of blocks (16-byte) to increment
 */
function incrementBlocks(counter, blockDelta) {
  if (blockDelta == 0) {
    return counter;
  }
  if (counter == null || counter.length != BLOCK_SIZE) {
    throw "Illegal argument";
  }
  if (blockDelta > MAX_GCM_BLOCKS) {
    throw "Illegal state";
  }

  var oldBlock = new Uint32Array(
    new Uint8Array(counter.buffer.slice(-4)).reverse(),
  )[0];
  var newBlock = oldBlock + blockDelta;
  if (newBlock > MAX_GCM_BLOCKS) {
    throw "Illegal state";
  } // overflow 2^32-2

  var ctrPart = new Uint8Array(new Uint32Array([newBlock]).buffer).reverse();

  counter.set(ctrPart, BLOCK_SIZE - 4);

  return counter;
}

/**
 * See <a href=
 * "http://csrc.nist.gov/publications/nistpubs/800-38D/SP-800-38D.pdf">
 * NIST Special Publication 800-38D.</a> for the definition of J0, the
 * "pre-counter block".
 * <p>
 * Reference: <a href=
 * "https://github.com/bcgit/bc-java/blob/master/core/src/main/java/org/bouncycastle/crypto/modes/GCMBlockCipher.java"
 * >GCMBlockCipher.java</a>
 */
function computeJ0(nonce) {
  var J0 = new Uint8Array(BLOCK_SIZE);
  J0.set(nonce, 0);
  J0.set([0x01], BLOCK_SIZE - 1);

  return incrementBlocks(J0, 1);
}

function adjustIV(iv, byteOffset) {
  // currently only support iv of length 12 for AES/GCM.
  // Anything else is quite a bit complicated.
  if (iv.length != 12) {
    throw "Unsupported operation";
  }
  var blockOffset = byteOffset / BLOCK_SIZE;
  if (blockOffset * BLOCK_SIZE != byteOffset) {
    throw "Expecting byteOffset to be multiple of 16";
  }

  var J0 = computeJ0(iv);
  return incrementBlocks(J0, blockOffset);
}

function str2ab(base64_string) {
  return Uint8Array.from(atob(base64_string), (c) => c.charCodeAt(0));
}

export function decryptWithKey(data, k, iv) {
  return new Promise((resolve, reject) => {
    window.crypto.subtle
      .decrypt(
        { name: "AES-CTR", counter: iv, length: BLOCK_SIZE * 8 },
        k,
        data,
      )
      .then(function (decrypted) {
        resolve(String.fromCharCode.apply(null, new Uint8Array(decrypted)));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function decrypt(data, key, iv, byteOffset) {
  iv = adjustIV(str2ab(iv), byteOffset);

  if (KEY_CACHE[key]) {
    return decryptWithKey(data, KEY_CACHE[key], iv);
  }

  return new Promise((resolve, reject) => {
    window.crypto.subtle
      .importKey("raw", str2ab(key), { name: "AES-CTR" }, false, ["decrypt"])
      .then(function (k) {
        KEY_CACHE[key] = k;
        decryptWithKey(data, k, iv)
          .then((r) => resolve(r))
          .catch((e) => reject(e));
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
