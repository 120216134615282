import React from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import * as moment from "moment-timezone";
import momentDurationFormatSetup from "moment-duration-format";

import { formatBytes, formatNumber } from "utils/format";
import DocsSnippet from "components/DocsSnippet";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";

import {
  VacuumDetails as VacuumDetailsType,
  VacuumDetailsVariables,
} from "./types/VacuumDetails";
import QUERY from "./Query.graphql";
import VacuumDetailsGraph from "components/VacuumDetailsGraph";
import { adaptVacuumDetailData } from "components/VacuumDetailsGraph/util";
import { useRoutes } from "utils/routes";
import { retention } from "utils/limits";

momentDurationFormatSetup(moment);

const VacuumDetails: React.FunctionComponent = () => {
  const { serverId, vacuumRunIdentity } = useParams();
  const { serverRole, databaseTable } = useRoutes();
  const { data, loading, error } = useQuery<
    VacuumDetailsType,
    VacuumDetailsVariables
  >(QUERY, {
    variables: {
      serverId,
      vacuumRunIdentity,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const vacuumRun = data.getVacuumRunDetails;

  if (!vacuumRun) {
    const vacuumRetention = retention.default;
    // The argument to humanize sets the threshold for the value of the given
    // unit. Since it's more natural to express these thresholds in days than in
    // months, set the threshold large enough to stay in days even if we
    // increase the retention significantly.
    const vacuumRetentionStr = vacuumRetention.humanize({ d: 100 });
    return (
      <PageContent
        title={`VACUUM Run ${vacuumRunIdentity}`}
        pageCategory="vacuums"
        pageName="show"
      >
        <Panel title="Information">
          <PanelSection>
            Statistics for this VACUUM run have already been deleted, currently
            statistics are kept for {vacuumRetentionStr}.
          </PanelSection>
        </Panel>
      </PageContent>
    );
  }

  return (
    <PageContent
      title={`VACUUM Run ${vacuumRun.identity}`}
      pageCategory="vacuums"
      pageName="show"
    >
      <Panel title="Information">
        <PanelTable horizontal borders>
          <tbody>
            <tr>
              <th>Table Name</th>
              <td>
                <span>
                  {!vacuumRun.schemaTable.id.startsWith("reserved:") ? (
                    <Link
                      to={databaseTable(
                        vacuumRun.database.id,
                        vacuumRun.schemaTable.id,
                      )}
                    >
                      {vacuumRun.schemaTable.schemaName}.
                      {vacuumRun.schemaTable.tableName}
                    </Link>
                  ) : (
                    vacuumRun.schemaTable.schemaName +
                    "." +
                    vacuumRun.schemaTable.tableName
                  )}
                  {vacuumRun.toast && " (TOAST)"}
                </span>
              </td>
              <th>Start Time</th>
              <td>{moment.unix(vacuumRun.vacuumStart).format("ll LTS z")}</td>
            </tr>
            <tr>
              <th>Duration</th>
              <td>
                {vacuumRun.vacuumEnd &&
                  moment
                    .duration(
                      moment
                        .unix(vacuumRun.vacuumEnd)
                        .diff(moment.unix(vacuumRun.vacuumStart)),
                    )
                    .humanize()}
                {!vacuumRun.vacuumEnd && "n/a"}
              </td>
              <th>End Time</th>
              <td>
                {(vacuumRun.vacuumEnd &&
                  moment.unix(vacuumRun.vacuumEnd).format("ll LTS z")) ||
                  "currently running"}
              </td>
            </tr>
            <tr>
              <th>Autovacuum</th>
              <td>{(vacuumRun.autovacuum && "Yes") || "No"}</td>
              <th>Postgres Role</th>
              <td>
                {(vacuumRun.postgresRole && (
                  <Link to={serverRole(serverId, vacuumRun.postgresRole.id)}>
                    {vacuumRun.postgresRole.name}
                  </Link>
                )) ||
                  "n/a"}
              </td>
            </tr>
            <tr>
              <th>Heap Blocks Total</th>
              <td>
                {formatBytes(vacuumRun.heapBlksTotalBytes)} ·{" "}
                {formatNumber(vacuumRun.heapBlksTotal)} blocks
              </td>
              <th>Max Dead Tuples / Phase</th>
              <td>{formatNumber(vacuumRun.maxDeadTuples)}</td>
            </tr>
          </tbody>
        </PanelTable>
      </Panel>
      <Panel title="Progress">
        {vacuumRun.stats.length > 0 && (
          <VacuumDetailsGraph
            blockSize={vacuumRun.heapBlksTotalBytes / vacuumRun.heapBlksTotal}
            data={adaptVacuumDetailData(
              vacuumRun.stats,
              vacuumRun.heapBlksTotal,
            )}
          />
        )}
        {vacuumRun.stats.length === 0 && (
          <PanelSection>
            <p>
              <strong>No progress information available</strong>
            </p>
            <p>
              This may be due to permission errors, or use of an old Postgres
              version - at least Postgres 9.6 is required.
            </p>
          </PanelSection>
        )}
      </Panel>
      <SummaryStats data={data} serverId={serverId} />
    </PageContent>
  );
};

const SummaryStats: React.FunctionComponent<{
  serverId: string;
  data: VacuumDetailsType;
}> = ({ serverId, data }) => {
  const vacuumRun = data.getVacuumRunDetails;
  if (!vacuumRun.autovacuum) {
    return null;
  }

  if (!vacuumRun.details) {
    return <SummaryStatsExcuse data={data} serverId={serverId} />;
  }

  const blockSize = data.getServerDetails.blockSize;
  const {
    aggressive,
    numIndexScans,
    pagesRemoved,
    relPages,
    pinskippedPages,
    frozenskippedPages,
    tuplesDeleted,
    newRelTuples,
    newDeadTuples,
    vacuumPageHit,
    vacuumPageMiss,
    vacuumPageDirty,
    readRateMb,
    writeRateMb,
    rusageUser,
    rusageKernel,
    elapsedSecs,
  } = vacuumRun.details;

  return (
    <Panel title="Summary Statistics">
      <PanelTable horizontal borders>
        <tbody>
          <tr>
            <th>
              Time Elapsed{" "}
              <DocsSnippet
                title="Time Elapsed"
                content="Exact time this vacuum run took, including CPU and I/O time."
              />
            </th>
            <td>
              {elapsedSecs === null && "Unknown"}
              {elapsedSecs !== null &&
                (moment.duration(elapsedSecs, "seconds") as any).format()}
            </td>
            <th>
              CPU Time{" "}
              <DocsSnippet
                title="CPU Time"
                content="Time spent in the Postgres process (user) and syscalls (system), as measured by getrusage."
              />
            </th>
            <td>
              {(rusageUser === null || rusageKernel === null) && "Unknown"}
              {rusageUser !== null &&
                rusageKernel !== null &&
                `user: ${rusageUser.toFixed(
                  2,
                )} s, system: ${rusageKernel.toFixed(2)} s`}
            </td>
          </tr>
          <tr>
            <th>
              Index Vacuum Phases{" "}
              <DocsSnippet
                title="# of Index Vacuum Phases"
                content="Number of index vacuum phases (typically 1, if higher consider raising maintenance_work_mem / autovacuum_work_mem)."
              />
            </th>
            <td>
              {numIndexScans === null && "Unknown"}
              {numIndexScans !== null && formatNumber(numIndexScans)}
            </td>
            <th>
              Aggressive Vacuum{" "}
              <DocsSnippet
                title="Aggressive Vacuum"
                content="Whether this was an aggressive vacuum, which runs if vacuum_freeze_table_age / vacuum_multixact_freeze_table_age is reached, or a VACUUM is triggered to prevent TXID wraparound."
              />
            </th>
            <td>
              {aggressive === null && "Unknown"}
              {aggressive !== null && ((aggressive && "Yes") || "No")}
            </td>
          </tr>
          <tr>
            <th>
              Pages Removed{" "}
              <DocsSnippet
                title="# of Pages Removed"
                content="Number of pages that were removed during vacuum."
              />
            </th>
            <td>
              {pagesRemoved === null && "Unknown"}
              {pagesRemoved !== null && (
                <span>
                  {formatNumber(pagesRemoved)} /{" "}
                  {formatBytes(pagesRemoved * blockSize)}
                </span>
              )}
            </td>
            <th>
              Pages Remaining{" "}
              <DocsSnippet
                title="# of Pages Remaining"
                content="Number of pages that remain in the table after the vacuum."
              />
            </th>
            <td>
              {relPages === null && "Unknown"}
              {relPages !== null && (
                <span>
                  {formatNumber(relPages)} / {formatBytes(relPages * blockSize)}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <th>
              Pages Skipped Due To Pin{" "}
              <DocsSnippet
                title="# of Pages Skipped Due To Pin"
                content="Pages that could not be vacuumed since they are pinned for usage by an active backend / connection."
              />
            </th>
            <td>
              {pinskippedPages === null && "Unknown"}
              {pinskippedPages !== null && (
                <span>
                  {formatNumber(pinskippedPages)} /{" "}
                  {formatBytes(pinskippedPages * blockSize)}
                </span>
              )}
            </td>
            <th>
              Pages Skipped Frozen{" "}
              <DocsSnippet
                title="# of Pages Skipped Frozen"
                content="Pages that did not need to be vacuumed because they are frozen - unchanged data since last vacuum."
              />
            </th>
            <td>
              {frozenskippedPages === null && "Unknown"}
              {frozenskippedPages !== null && (
                <span>
                  {formatNumber(frozenskippedPages)} /{" "}
                  {formatBytes(frozenskippedPages * blockSize)}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <th>
              Tuples Deleted{" "}
              <DocsSnippet
                title="# of Tuples Deleted"
                content="Number of rows that were deleted and cleaned up by vacuum."
              />
            </th>
            <td>
              {tuplesDeleted === null && "Unknown"}
              {tuplesDeleted !== null && formatNumber(tuplesDeleted)}
            </td>
            <th>
              Tuples Remaining{" "}
              <DocsSnippet
                title="# of Tuples Remaining"
                content="Number of rows remaining in the table after vacuum."
              />
            </th>
            <td>
              {newRelTuples === null && "Unknown"}
              {newRelTuples !== null && formatNumber(newRelTuples)}
            </td>
          </tr>
          <tr>
            <th>
              Tuples Dead But Not Removable{" "}
              <DocsSnippet
                title="# of Tuples Dead But Not Removable"
                content="Number of tuples that are dead but couldn't be removed yet by this vacuum."
              />
            </th>
            <td>
              {newDeadTuples === null && "Unknown"}
              {newDeadTuples !== null && formatNumber(newDeadTuples)}
            </td>
            <th>
              Data Read from Cache{" "}
              <DocsSnippet
                title="Data Read from Cache"
                content="Amount of table and index data read from Postgres buffer cache."
              />
            </th>
            <td>
              {vacuumPageHit === null && "Unknown"}
              {vacuumPageHit !== null && formatBytes(vacuumPageHit * blockSize)}
            </td>
          </tr>
          <tr>
            <th>
              Data Read from Disk{" "}
              <DocsSnippet
                title="Data Read from Disk"
                content="Amount of table and index data read from disk and the page cache."
              />
            </th>
            <td>
              {vacuumPageMiss === null && "Unknown"}
              {vacuumPageMiss !== null &&
                formatBytes(vacuumPageMiss * blockSize)}
            </td>
            <th>
              Data Flushed to Disk{" "}
              <DocsSnippet
                title="Data Flushed to Disk"
                content="Dirty buffers (data modified but not yet flushed to disk) flushed to disk during the vacuum."
              />
            </th>
            <td>
              {vacuumPageDirty === null && "Unknown"}
              {vacuumPageDirty !== null &&
                formatBytes(vacuumPageDirty * blockSize)}
            </td>
          </tr>
          <tr>
            <th>
              Avg Read Rate{" "}
              <DocsSnippet
                title="Avg Read Rate"
                content="Average rate of reading data needed for the vacuum."
              />
            </th>
            <td>
              {readRateMb === null && "Unknown"}
              {readRateMb !== null && readRateMb.toFixed(2) + " MB/s"}
            </td>
            <th>
              Avg Write Rate{" "}
              <DocsSnippet
                title="Avg Write Rate"
                content="Average rate of writing newly vacuumed data."
              />
            </th>
            <td>
              {writeRateMb === null && "Unknown"}
              {writeRateMb !== null && writeRateMb.toFixed(2) + " MB/s"}
            </td>
          </tr>
        </tbody>
      </PanelTable>
    </Panel>
  );
};

const SummaryStatsExcuse: React.FunctionComponent<{
  serverId: string;
  data: VacuumDetailsType;
}> = ({ serverId, data }) => {
  const { serverLogs, serverConfigSetting } = useRoutes();
  const vacuumRun = data.getVacuumRunDetails;
  const minDuration = data.logAutovacuumMinDurationSetting.value;
  let excuse: React.ReactNode = null;

  if (!data.getServerDetails.integratedLogInsights) {
    excuse = (
      <p>
        You have not yet integrated pganalyze{" "}
        <strong>
          <Link to={serverLogs(serverId)}>Log Insights</Link>
        </strong>{" "}
        for this server, which is required to collect this data.
      </p>
    );
  } else if (!vacuumRun.vacuumEnd) {
    excuse = (
      <p>
        This vacuum is currently running. Summary statistics are only available
        for completed vacuum runs.
      </p>
    );
  } else if (minDuration === "" || minDuration === "-1") {
    excuse = (
      <p>
        You have not configured{" "}
        <Link to={serverConfigSetting(serverId, "log_autovacuum_min_duration")}>
          log_autovacuum_min_duration
        </Link>
        . Only vacuum runs slower than the threshold have summary statistics
        available.
      </p>
    );
  } else if (
    moment.unix(vacuumRun.vacuumEnd).diff(moment.unix(vacuumRun.vacuumStart)) <
    parseInt(minDuration)
  ) {
    excuse = (
      <p>
        This vacuum run finished faster than the{" "}
        <Link to={serverConfigSetting(serverId, "log_autovacuum_min_duration")}>
          log_autovacuum_min_duration
        </Link>{" "}
        threshold ({minDuration} seconds).
      </p>
    );
  } else {
    excuse = (
      <p>
        We could not match the log data to this vacuum run. This may occur
        occassionally due to missing log events, or if you recently changed your{" "}
        <Link to={serverConfigSetting(serverId, "log_autovacuum_min_duration")}>
          log_autovacuum_min_duration
        </Link>
        . Please check <Link to={serverLogs(serverId)}>Log Insights</Link> for
        vacuum log events at this time.
      </p>
    );
  }

  return (
    <Panel title="Summary Statistics">
      <PanelSection>
        <p>
          <strong>No summary statistics available</strong>
        </p>
        {excuse}
      </PanelSection>
    </Panel>
  );
};

export default VacuumDetails;
