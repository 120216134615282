import React from "react";

import classNames from "classnames";

import { DialogOverlay, DialogContent } from "@reach/dialog";

export const ModalContent = ({
  children,
  className,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  const cn = classNames(
    "absolute top-0 right-0 left-0 min-h-screen bg-white m-0 md:ml-[300px] shadow-md p-0 w-auto",
    className,
  );
  return <DialogContent className={cn}>{children}</DialogContent>;
};

export const ModalOverlay = ({
  children,
  className,
  onDismiss,
}: {
  className?: string;
  onDismiss: () => void;
  children: React.ReactNode;
}) => {
  const cn = classNames(
    "fixed top-0 right-0 bottom-0 left-0 overflow-scroll isolate z-10",
    className,
  );
  return (
    <DialogOverlay isOpen className={cn} onDismiss={onDismiss}>
      {children}
    </DialogOverlay>
  );
};
