import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import QUERY from "./Query.graphql";
import {
  ExplainWorkspaces as ExplainWorkspacesType,
  ExplainWorkspacesVariables,
} from "./types/ExplainWorkspaces";
import Loading from "components/Loading";
import Panel from "components/Panel";
import CreateWorkspacePanel from "./CreateWorkspacePanel";
import Grid from "components/Grid";
import { formatTimestampShort } from "utils/format";
import moment from "moment";
import { useRoutes } from "utils/routes";
import FilterSearch from "components/FilterSearch";
import { makeFilter } from "utils/filter";

const ExplainWorkspaceList = () => {
  const [showCreateWorkspacePanel, setShowCreateWorkspacePanel] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { databaseQueriesExplainsWorkspace } = useRoutes();

  const { databaseId, serverId } = useParams();
  const { loading, error, data, refetch } = useQuery<
    ExplainWorkspacesType,
    ExplainWorkspacesVariables
  >(QUERY, {
    variables: { databaseId, serverId },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const secondaryTitle = (
    <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
  );

  const filteredData = data.getExplainWorkspaces.filter(
    makeFilter(searchTerm, "name"),
  );

  function handleCreateDismiss() {
    setShowCreateWorkspacePanel(false);
    refetch();
  }

  return (
    <>
      <div className="flex justify-end mb-2">
        <button
          className="btn btn-success"
          onClick={() => setShowCreateWorkspacePanel(true)}
        >
          New Workspace
        </button>
      </div>
      <Panel title="All Workspaces" secondaryTitle={secondaryTitle}>
        <Grid
          className="grid-cols-[1fr_200px_300px]"
          data={filteredData}
          defaultSortBy="lastActivityAt"
          columns={[
            {
              field: "name",
              header: "Workspace",
              renderer: function NameCell({ rowData, fieldData }) {
                return (
                  <div className="flex flex-col">
                    <Link
                      to={databaseQueriesExplainsWorkspace(
                        databaseId,
                        rowData.id,
                      )}
                    >
                      {fieldData}
                    </Link>
                    {rowData.user?.fullname}
                  </div>
                );
              },
            },
            {
              field: "experimentsCount",
              header: "Experiment",
              style: "number",
            },
            {
              field: "lastActivityAt",
              header: "Last Activity",
              defaultSortOrder: "desc",
              className: "text-right",
              headerClassName: "text-right",
              renderer: function LastActivityCell({ fieldData }) {
                return formatTimestampShort(moment.unix(fieldData));
              },
            },
          ]}
        />
      </Panel>
      {showCreateWorkspacePanel && (
        <CreateWorkspacePanel
          onDismiss={handleCreateDismiss}
          databaseId={databaseId}
        />
      )}
    </>
  );
};

export default ExplainWorkspaceList;
