import React from "react";
import { useParams } from "react-router-dom";

import { retention } from "utils/limits";
import { WithDateRange } from "components/WithDateRange";
import DateRangeBar from "components/DateRangeBar";
import LogLinesAndStats from "components/LogLinesAndStats";
import PageContent from "components/PageContent";

import directory from "../../../../docs/directory.json";

import styles from "./style.module.scss";
import { BookIcon } from "components/Icons";

const LogInsights: React.FunctionComponent = () => {
  const {
    databaseId,
    serverId,
    classification: activeClassification,
  } = useParams();
  const details = activeClassification
    ? directory.logClassifications[activeClassification]
    : null;
  const featureInfo = activeClassification ? (
    <>
      <p>{details.tooltip}</p>
      <a
        href={`https://pganalyze.com` + details.path}
        target="_blank"
        className={styles.detailsDocsLink}
      >
        <BookIcon /> Learn more
      </a>
    </>
  ) : null;

  return (
    <WithDateRange initialHorizon={retention.logs}>
      <PageContent
        title={
          activeClassification
            ? `Log Insights - ${activeClassification}: ${details.title}`
            : "Log Insights"
        }
        pageControls={<DateRangeBar feature="Log" />}
        windowTitle="Log Insights"
        pageCategory={serverId ? "server" : "database"}
        pageName="logs"
        featureInfo={featureInfo}
      >
        <LogLinesAndStats
          databaseId={databaseId}
          serverId={serverId}
          activeClassification={activeClassification}
        />
      </PageContent>
    </WithDateRange>
  );
};

export default LogInsights;
