import PageContent from "components/PageContent";
import Panel from "components/Panel";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import UPDATE_MUTATION from "./Mutation.update.graphql";
import WORKSPACE_DETAIL_QUERY from "../Query.graphql";
import UNSELECT_MUTATION from "../ExplainWorkspaceRunExplain/Mutation.unselect.graphql";
import Grid from "components/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandableSQL from "components/ExpandableSQL";
import {
  ExplainWorkspaceDetails_getExplainWorkspaceDetails as ExplainWorkspaceType,
  ExplainWorkspaceDetails_getExplainWorkspaceDetails_explainQueries as ExplainQueryType,
} from "../types/ExplainWorkspaceDetails";
import {
  convertParamValue,
  JsonParametersType,
  renderKeyValueForParam,
} from "../util";
import { ExplainWorkspaceHeader } from "..";
import Callout from "components/Callout";
import {
  faCheckCircle,
  faEdit,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import {
  UpdateExplainParameterSets,
  UpdateExplainParameterSetsVariables,
} from "./types/UpdateExplainParameterSets";
import {
  UpdateExplainParameterSetsSelected,
  UpdateExplainParameterSetsSelectedVariables,
} from "../ExplainWorkspaceRunExplain/types/UpdateExplainParameterSetsSelected";
import { useParams } from "react-router-dom";

const ExplainWorkspaceParameterSetsEdit = ({
  workspace,
  explainQuery,
  featureNav,
}: {
  workspace: ExplainWorkspaceType;
  explainQuery: ExplainQueryType;
  featureNav: React.ReactNode;
}) => {
  const { databaseId } = useParams();
  const [focusedParameterSet, setFocusedParameterSet] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [updateExplainWorkspace] = useMutation<
    UpdateExplainParameterSetsSelected,
    UpdateExplainParameterSetsSelectedVariables
  >(UNSELECT_MUTATION, {
    refetchQueries: [
      {
        query: WORKSPACE_DETAIL_QUERY,
        variables: { workspaceId: workspace.id, databaseId },
      },
    ],
  });

  const expectedAliasesLength = workspace.parameterRefAliases.length;
  // For the case the workspace was initially created without params but new param set is added
  const newParamNotFilled =
    workspace.parameterSets.length === 0 && expectedAliasesLength > 0;
  const allValuesSet =
    !newParamNotFilled &&
    workspace.parameterSets.every(
      (pset) => pset.paramValues.length === expectedAliasesLength,
    );

  const handleRunExplain = () => {
    updateExplainWorkspace({
      variables: {
        workspaceId: workspace.id,
        parameterSetsSelected: true,
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  // TODO: show component to allow creating a new param set when newParamNotFilled
  return (
    <PageContent
      windowTitle={`EXPLAIN Workspace: ${workspace.name}`}
      featureInfo={<ExplainWorkspaceHeader workspace={workspace} />}
      pageCategory="explains"
      pageName="workspaces"
      layout="sidebar"
      featureNav={featureNav}
    >
      {/* main content */}
      {!allValuesSet && (
        <Callout
          title="Parameter Sets need an update"
          variant="warning"
          className="mb-4"
        >
          New parameters are added with this new experiment. Please update
          parameter set values.
        </Callout>
      )}
      <div className="mb-4 leading-6">
        <ExpandableSQL sql={explainQuery.queryTextWithAlias} />
      </div>
      <Panel title="Existing Parameter Sets">
        <Grid
          className="grid-cols-1fr"
          data={workspace.aliasParamMapList}
          columns={[
            {
              field: "id",
              header: "Parameter Set",
              renderer: function ParametersCell({ rowData, fieldData }) {
                return (
                  <ParameterSetCell
                    setId={fieldData}
                    parameterSet={rowData.parameters}
                    workspace={workspace}
                    focusedParameterSet={focusedParameterSet}
                    setFocusedParameterSet={setFocusedParameterSet}
                    setErrorMessage={setErrorMessage}
                  />
                );
              },
              className: "whitespace-normal",
              disableSort: true,
            },
          ]}
        />
      </Panel>
      {errorMessage && <div className="text-[#FF0000]">{errorMessage}</div>}
      <div className="grid">
        <div className="justify-self-end">
          <button
            className="btn btn-success !px-4"
            disabled={!allValuesSet}
            onClick={handleRunExplain}
          >
            Run EXPLAIN...
          </button>
        </div>
      </div>
      {/* sidebar */}
      <div className="w-[320px]">
        <h4 className="leading-7 mt-0">Parameter Sets</h4>
        <p>
          Parameter sets refer to the use of placeholders in SQL queries,
          allowing for the dynamic substitution of values at execution time to
          enhance security and performance. This technique helps prevent SQL
          injection attacks and improves query efficiency by reusing the
          execution plan with different parameter values.
        </p>
        <h4 className="leading-7">Query Samples</h4>
        <p>
          Query samples in PostgreSQL refer to examples or instances of SQL
          queries that are used for testing, optimization, or benchmarking
          purposes. These samples help in analyzing query performance,
          identifying potential bottlenecks, and ensuring that the database can
          handle various types of queries efficiently.
        </p>
      </div>
    </PageContent>
  );
};

const ParameterSetCell = ({
  setId,
  parameterSet,
  workspace,
  focusedParameterSet,
  setFocusedParameterSet,
  setErrorMessage,
}: {
  setId: string;
  parameterSet: JsonParametersType;
  workspace: ExplainWorkspaceType;
  focusedParameterSet: string;
  setFocusedParameterSet: (setId: string) => void;
  setErrorMessage: (message: string) => void;
}) => {
  const [newParamValues, setNewParamValues] = useState<{
    [key: string]: string;
  }>({});

  const [updateExplainParameterSets] = useMutation<
    UpdateExplainParameterSets,
    UpdateExplainParameterSetsVariables
  >(UPDATE_MUTATION);

  const focused = setId === focusedParameterSet;

  const existingParam = workspace.parameterSets.find((val) => val.id === setId);
  const expectedAliases = workspace.parameterRefAliases;

  const missingAliases = expectedAliases.slice(
    existingParam.paramValues.length,
  );

  const handleParamValuesChange = (alias: string, value: string) => {
    const newSet = { ...newParamValues };
    if (value === "") {
      // remove from the set when the value becomes an empty string
      delete newSet[alias];
      setNewParamValues(newSet);
      return;
    }
    newSet[alias] = convertParamValue(value);
    setNewParamValues(newSet);
  };

  const handleUpdateParameters = () => {
    if (Object.keys(newParamValues).length !== missingAliases.length) {
      setErrorMessage(
        "Please enter values for all parameters missing values. Use 'null' for NULL values.",
      );
      return;
    }
    updateExplainParameterSets({
      variables: {
        workspaceId: workspace.id,
        parameterSetId: setId,
        parameters: newParamValues,
      },
      onCompleted: () => {
        setErrorMessage("");
        setNewParamValues({});
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  return (
    <div
      className={missingAliases.length > 0 ? "cursor-pointer" : undefined}
      onClick={() => {
        setFocusedParameterSet(setId);
      }}
    >
      <div className="grid grid-cols-[1fr_40px] items-center">
        <div className="flex gap-2 items-center">
          {missingAliases.length === 0 ? (
            <FontAwesomeIcon icon={faCheckCircle} />
          ) : (
            <FontAwesomeIcon icon={faTriangleExclamation} />
          )}
          <pre className="border-none m-0 p-0 bg-none bg-transparent whitespace-pre-wrap">
            {Object.entries(parameterSet).map(([key, value], i, arr) => {
              const missing = missingAliases.includes(key.slice(1));
              const missingClass = missing ? "text-[#A16006]" : "";
              return (
                <React.Fragment key={key}>
                  <span className={missingClass}>
                    {missing ? key : renderKeyValueForParam(key, value)}
                  </span>
                  {i + 1 < arr.length && ", "}
                </React.Fragment>
              );
            })}
          </pre>
        </div>
        <div className="justify-self-end">
          {missingAliases.length > 0 && (
            <FontAwesomeIcon
              icon={faEdit}
              title="Edit"
              className="mr-1 justify-end"
            />
          )}
        </div>
      </div>
      {focused && missingAliases.length > 0 && (
        <div>
          <div className="grid grid-cols-2 gap-2 mb-2 mt-2">
            {missingAliases.map((alias) => {
              return (
                <div
                  className="grid grid-cols-[min-content_1fr] items-center gap-2"
                  key={alias}
                >
                  <div className="font-medium">${alias}</div>
                  <input
                    className="bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5"
                    type="text"
                    placeholder="Type 'null' for null value"
                    onChange={(e) => {
                      handleParamValuesChange(alias, e.target.value);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div>
            <button
              className="btn btn-success"
              onClick={handleUpdateParameters}
            >
              Update parameters
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExplainWorkspaceParameterSetsEdit;
