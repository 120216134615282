import React from "react";
import { useQuery } from "@apollo/client";

import ExplainTable from "components/ExplainTable";
import Loading from "components/Loading";
import LogProcessingDisabledPanel from "components/LogProcessingDisabledPanel";
import Panel from "components/Panel";
import QueryExplainsBlankSlate from "components/QueryExplainsBlankSlate";

import QUERY from "./Query.graphql";
import {
  QueryExplainList as QueryExplainType,
  QueryExplainListVariables,
} from "./types/QueryExplainList";

const QueryExplainList: React.FunctionComponent<{
  queryId: string;
  databaseId: string;
  blockSize: number;
}> = ({ databaseId, queryId, blockSize }) => {
  const { error, loading, data } = useQuery<
    QueryExplainType,
    QueryExplainListVariables
  >(QUERY, {
    variables: {
      queryId: queryId,
      databaseId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  let warning;
  if (data.getServerDetails.collectorInfo?.logCollectionDisabled) {
    warning = (
      <LogProcessingDisabledPanel
        disabledReasons={
          data.getServerDetails.collectorInfo?.logCollectionDisabledReason
        }
      />
    );
  }

  const explains = data.getQueryExplains;
  if (explains.length === 0) {
    return (
      warning || (
        <QueryExplainsBlankSlate serverId={data.getServerDetails.humanId} />
      )
    );
  }

  return (
    <>
      {warning}
      <Panel title={`EXPLAINs`}>
        <ExplainTable
          databaseId={databaseId}
          explains={explains}
          blockSize={blockSize}
        />
      </Panel>
    </>
  );
};

export default QueryExplainList;
