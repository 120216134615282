import React from "react";

import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import ExplainPanel from "components/ExplainPanel";

import QUERY from "./Query.graphql";
import {
  QueryExplain as QueryExplainType,
  QueryExplainVariables,
} from "./types/QueryExplain";
import QueryExplainError, {
  QueryExplainLoadError,
} from "components/QueryExplainError";

type Props = {
  explainId: string;
  databaseId: string;
  blockSize: number;
};

const QueryExplain: React.FunctionComponent<Props> = ({
  explainId,
  databaseId,
  blockSize,
}) => {
  const { error, loading, data } = useQuery<
    QueryExplainType,
    QueryExplainVariables
  >(QUERY, {
    variables: {
      explainId,
      databaseId,
    },
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <QueryExplainLoadError databaseId={databaseId} />;
  }

  const explain = data.getQueryExplain;

  if (!explain) {
    return <Loading error />;
  }

  if (explain.error) {
    return (
      <QueryExplainError
        error={explain.error}
        source={explain.source}
        queryText={explain.querySample?.queryText}
      />
    );
  }

  return (
    <ExplainPanel
      explain={explain}
      databaseId={databaseId}
      blockSize={blockSize}
    />
  );
};

export default QueryExplain;
