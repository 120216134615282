import React, { useCallback, useEffect, useMemo, useState } from "react";

import LZString from "lz-string";
import { useMutation, useQuery } from "@apollo/client";
import queryString from "query-string";

import Loading from "components/Loading";
import IndexSelectionResults from "components/IndexSelectionResults";

import QUERY from "./Query.graphql";
import SUMMARY_QUERY from "./Query.summary.graphql";
import MUTATION from "./Mutation.graphql";
import {
  IndexSelection as IndexSelectionType,
  IndexSelectionVariables,
} from "./types/IndexSelection";
import {
  IndexAdvisorConfig as IndexAdvisorConfigType,
  IndexAdvisorConfigVariables,
  IndexAdvisorConfig_getSchemaTableIndexAdvisorConfig as SelectionConfigType,
} from "./types/IndexAdvisorConfig";
import PanelTileGroup, { PanelTile } from "components/PanelTileGroup";
import {
  formatBytes,
  formatNumber,
  formatNumberWithThreshold,
  formatTimeAgo,
} from "utils/format";
import {
  BALANCED_DEFAULT_SETTINGS,
  getNameByPreset,
  getPresetByName,
  transformIndexSelectionResult,
} from "./util";
import {
  Link,
  NavigateOptions,
  useLocation,
  useNavigate,
} from "react-router-dom";
import EditSettingsPanel, {
  IndexSelectionCustomConfig,
} from "./EditSettingsPanel";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import {
  faCircleCheck,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import Callout from "components/Callout";
import {
  UpdateIndexingEngineSelectionOptionOverride,
  UpdateIndexingEngineSelectionOptionOverrideVariables,
} from "./types/UpdateIndexingEngineSelectionOptionOverride";
import { useAsyncActionFlash } from "components/WithFlashes";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Grid from "components/Grid";
import { useRoutes } from "utils/routes";
import { useCurrentOrganization } from "components/WithCurrentOrganization";
import WithCustomSettings, { useCustomSettings } from "./CustomSettingsContext";

const SchemaTableIndexAdvisor: React.FunctionComponent<{
  databaseId: string;
  tableId: string;
  schemaName: string;
  tableName: string;
}> = ({ databaseId, tableId, schemaName, tableName }) => {
  const { loading, error, data } = useQuery<
    IndexAdvisorConfigType,
    IndexAdvisorConfigVariables
  >(SUMMARY_QUERY, {
    variables: {
      databaseId,
      tableId,
    },
  });

  if (error) {
    return <Loading error={!!error} />;
  }

  return (
    <>
      <Summary summaryConfig={data} loading={loading} />
      {!loading && (
        <WithCustomSettings>
          <IndexSelection
            databaseId={databaseId}
            tableId={tableId}
            schemaName={schemaName}
            tableName={tableName}
            selectionConfig={data.getSchemaTableIndexAdvisorConfig}
          />
        </WithCustomSettings>
      )}
    </>
  );
};

const IndexSelection: React.FunctionComponent<{
  databaseId: string;
  tableId: string;
  schemaName: string;
  tableName: string;
  selectionConfig: SelectionConfigType;
}> = ({ databaseId, tableId, schemaName, tableName, selectionConfig }) => {
  const [lastTriedSettings, updateLastTriedSettings] = useCustomSettings();
  const applySettings = useApplySettingsToRoute();
  const routeSettings = useSettingsFromRoute();

  const navigate = useNavigate();
  const { hash } = useLocation();

  const savedSettingsName = selectionConfig.settingsName;
  const savedSettings = selectionConfig.settings;
  const savedLastRunAt = selectionConfig.lastRunAt;
  const hasScans = selectionConfig.scansPerMin > 0;
  const defaultPreset = selectionConfig.defaultPreset;
  useEffect(() => {
    const qs = window.location.search;
    // Skip early return with `preset=default` as we want to run the applySettings
    // to rewrite it to the actual preset
    if (
      (qs.includes("settings=") || qs.includes("preset=")) &&
      !qs.includes("preset=default")
    ) {
      return;
    }
    applySettings(savedSettingsName, JSON.stringify(savedSettings), {
      replace: true,
    });
    // Trigger useEffect with defaultPreset too to run applySettings after saving the configuration
    // even without savedSettingsName change (e.g. override "Balanced" to default "Balanced")
  }, [applySettings, savedSettings, savedSettingsName, defaultPreset]);

  const [useConsolidation, setUseConsolidation] = useState(false);

  const variables: IndexSelectionVariables = {
    databaseId,
    tableId,
    useConsolidation,
  };

  // When the route preset is default, use the defaultPreset as preset
  const presetFromRoute =
    routeSettings.preset === "default" ? defaultPreset : routeSettings.preset;
  // If a preset is present in the route, use that. Otherwise, get the preset
  // from the saved settings _unless_ we're using custom settings.
  const preset =
    presetFromRoute ??
    (!routeSettings.settings && getPresetByName(savedSettingsName));
  const settingsJson =
    routeSettings.settings ??
    lastTriedSettings ??
    JSON.stringify(savedSettings ?? BALANCED_DEFAULT_SETTINGS, null, 2);
  if (preset) {
    variables.preset = preset;
    variables.useConsolidation = false;
  } else {
    variables.options = settingsJson;
  }

  const showSettingsEditor = hash === "#edit_settings";
  const skipIndexSelection =
    showSettingsEditor || (!variables.options && !variables.preset);
  const { data, previousData, loading, error } = useQuery<
    IndexSelectionType,
    IndexSelectionVariables
  >(QUERY, {
    variables,
    skip: skipIndexSelection,
  });

  function handleTryCustomSettings(config: IndexSelectionCustomConfig) {
    if (config.preset) {
      applySettings(getNameByPreset(config.preset), null);
    } else {
      setUseConsolidation(config.useConsolidation);
      updateLastTriedSettings(config.settingsJson);
      applySettings("Custom", config.settingsJson);
    }
  }

  function handleEditDismiss() {
    navigate(-1);
  }

  const hasSelectionResult = !!(data ?? previousData)
    ?.getSchemaTableIndexSelection.data.output;
  const resultData = data
    ? data
    : showSettingsEditor && previousData
    ? { ...previousData }
    : undefined;
  const usingSavedSelection =
    // current preset is the same as the stored one
    // (write_optimized, read_optimized, balanced, or ignored)
    routeSettings.preset === getPresetByName(savedSettingsName) ||
    // stored setting is custom and no preset is set, and settings are the same
    (savedSettingsName === "Custom" &&
      !routeSettings.preset &&
      settingsJson === JSON.stringify(savedSettings, null, 2));
  const ignored = routeSettings.preset === "ignored";
  const { slug: organizationSlug } = useCurrentOrganization();
  const { organizationMembers } = useRoutes();
  return (
    <div>
      {!(loading || error) &&
        !usingSavedSelection &&
        !showSettingsEditor &&
        (selectionConfig.permittedToEdit ? (
          <SavingCallout
            schemaName={schemaName}
            tableName={tableName}
            databaseId={databaseId}
            tableId={tableId}
            settings={settingsJson}
            preset={routeSettings.preset}
            defaultPreset={defaultPreset}
          />
        ) : (
          <Callout
            title={`Index Advisor configuration for table ${schemaName}.${tableName} not saved`}
            variant="warning"
            className="mb-5"
          >
            You do not have enough permissions to save the configuration.{" "}
            <Link to={organizationMembers(organizationSlug)} target="_blank">
              Ask a team member
            </Link>{" "}
            with Modify permissions to change the configuration of this table.
          </Callout>
        ))}
      {(hasSelectionResult || hasScans) && (
        <SettingsButtonBar
          storedSettingsName={savedSettingsName}
          defaultPreset={defaultPreset}
          currentPreset={routeSettings.preset}
          currentSettings={routeSettings.settings}
          storedLastRunAt={savedLastRunAt}
        />
      )}
      {showSettingsEditor && (
        <EditSettingsPanel
          schemaName={schemaName}
          tableName={tableName}
          onTry={handleTryCustomSettings}
          onDismiss={handleEditDismiss}
          initialConfig={{
            settingsJson,
            useConsolidation,
          }}
          storedSettingsName={savedSettingsName}
          defaultSettingsName={getNameByPreset(defaultPreset)}
          hasOverride={!!defaultPreset}
        />
      )}
      {loading || error ? (
        <Loading error={!!error} />
      ) : resultData && hasSelectionResult && !ignored ? (
        <IndexSelectionResultsAdapter
          data={resultData}
          databaseId={databaseId}
          runAt={resultData.getSchemaTableIndexSelection.runAt}
          useConsolidation={!preset && useConsolidation}
        />
      ) : !showSettingsEditor ? (
        <IndexSelectionNoResultsPanel
          databaseId={databaseId}
          data={resultData}
          preset={routeSettings.preset}
          selectionRunnable={hasSelectionResult || hasScans}
          parentTableId={selectionConfig.parentTableId}
        />
      ) : null}
    </div>
  );
};

function useApplySettingsToRoute() {
  const navigate = useNavigate();
  const applySettings = useCallback(
    (settingsName: string, settingsJson: string, opts?: NavigateOptions) => {
      const qs: { [key: string]: string } = {};
      const preset = getPresetByName(settingsName);
      if (preset) {
        qs.preset = preset;
      } else if (settingsName === "Custom") {
        qs.settings = LZString.compressToEncodedURIComponent(settingsJson);
      }

      navigate(
        {
          search: queryString.stringify(qs),
        },
        opts,
      );
    },
    [navigate],
  );

  return applySettings;
}

function valueOrFirst(input: string | string[] | undefined): string {
  return typeof input === "string" || typeof input === "undefined"
    ? input
    : input[0];
}

function useSettingsFromRoute() {
  const loc = useLocation();
  const qs = queryString.parse(loc.search);
  return {
    preset: valueOrFirst(qs.preset),
    settings: qs.settings
      ? LZString.decompressFromEncodedURIComponent(valueOrFirst(qs.settings))
      : undefined,
  };
}

const IndexSelectionResultsAdapter: React.FunctionComponent<{
  data: IndexSelectionType;
  databaseId: string;
  runAt: number;
  useConsolidation: boolean;
}> = ({ data, databaseId, runAt, useConsolidation }) => {
  const parsedResult = useMemo(() => {
    return transformIndexSelectionResult(data, moment.unix(runAt));
  }, [data, runAt]);

  return (
    <IndexSelectionResults
      result={parsedResult}
      databaseId={databaseId}
      useConsolidation={useConsolidation}
      verbose
    />
  );
};

const IndexSelectionNoResultsPanel: React.FunctionComponent<{
  databaseId: string;
  data: IndexSelectionType;
  preset: string;
  selectionRunnable: boolean;
  parentTableId: string;
}> = ({ databaseId, data, preset, selectionRunnable, parentTableId }) => {
  const { databaseIndex, databaseTableIndexSelection } = useRoutes();
  const indexes = data.getSchemaTableIndices;
  const content = parentTableId ? (
    <>
      Index Advisor skips{" "}
      <a
        target="_blank"
        rel="noopener"
        href="https://www.postgresql.org/docs/current/ddl-partitioning.html#DDL-PARTITIONING-DECLARATIVE"
      >
        individual partitions of a partitioned table
      </a>{" "}
      and{" "}
      <a
        target="_blank"
        rel="noopener"
        href="https://www.postgresql.org/docs/current/ddl-inherit.html"
      >
        inheritance children
      </a>
      .{" "}
      <Link to={databaseTableIndexSelection(databaseId, parentTableId)}>
        Click here
      </Link>{" "}
      to review Index Advisor insights for the parent table.
    </>
  ) : selectionRunnable ? (
    <>
      Try one of the default configurations or a custom configuration to see
      insights about indexes to add or remove.
    </>
  ) : (
    <>
      Index Advisor could not run on this table, because there were no queries
      or scans detected in the last 7 days.
    </>
  );

  return (
    <>
      <Panel
        title={`Configuration: ${
          parentTableId ? "Skipped (Child Table)" : getNameByPreset(preset)
        }`}
      >
        <PanelSection>{content}</PanelSection>
      </Panel>
      <Panel title="Existing Indexes">
        <Grid
          className="grid-cols-[50%_50%]"
          data={indexes}
          columns={[
            {
              field: "name",
              header: "Index",
              renderer: function IndexChangeCell({ rowData, fieldData }) {
                return (
                  <Link to={databaseIndex(databaseId, rowData.id)}>
                    {fieldData}
                  </Link>
                );
              },
            },
            {
              field: "constraintDef",
              header: "Constraint",
              style: "query",
            },
          ]}
        />
      </Panel>
    </>
  );
};

const SettingsButtonBar: React.FunctionComponent<{
  storedSettingsName: string;
  defaultPreset?: string;
  currentPreset?: string;
  currentSettings?: string;
  storedLastRunAt?: number;
}> = ({
  storedSettingsName,
  defaultPreset,
  currentPreset,
  currentSettings,
  storedLastRunAt,
}) => {
  function isActive(preset: string) {
    return (
      currentPreset === preset ||
      (currentPreset === "default" && preset === defaultPreset)
    );
  }
  return (
    <div className="flex flex-col mb-5 lg:flex-row rounded-md border gap-[1px] bg-[#E8E8EE] border-[#E8E8EE] overflow-clip">
      <SettingsButtonLink
        to="?preset=read_optimized"
        title="Read-optimized"
        isActive={isActive("read_optimized")}
        isStored={storedSettingsName === "Read-optimized"}
        storedLastRunAt={storedLastRunAt}
      />
      <SettingsButtonLink
        to="?preset=balanced"
        title="Balanced"
        isActive={isActive("balanced")}
        isStored={storedSettingsName === "Balanced"}
        storedLastRunAt={storedLastRunAt}
      />
      <SettingsButtonLink
        to="?preset=write_optimized"
        title="Write-optimized"
        isActive={isActive("write_optimized")}
        isStored={storedSettingsName === "Write-optimized"}
        storedLastRunAt={storedLastRunAt}
      />
      <SettingsButtonLink
        to="#edit_settings"
        title="Custom Configuration"
        isActive={!!currentSettings}
        isStored={storedSettingsName === "Custom"}
        storedLastRunAt={storedLastRunAt}
      />
    </div>
  );
};

const SettingsButtonLink: React.FunctionComponent<{
  to: string;
  title: React.ReactNode;
  isActive: boolean;
  isStored: boolean;
  storedLastRunAt?: number;
}> = ({ to, title, isActive, isStored, storedLastRunAt }) => {
  const badgeClass = "relative -top-0.5 ml-1 text-xs";
  const badge = isStored ? (
    <FontAwesomeIcon
      className={classNames(badgeClass, "text-[#337AB7]")}
      icon={faCircleCheck}
    />
  ) : isActive ? (
    <FontAwesomeIcon
      className={classNames(badgeClass, "text-[#CA8A04]")}
      icon={faTriangleExclamation}
    />
  ) : null;
  const description = isStored ? (
    `Last run: ${
      storedLastRunAt ? formatTimeAgo(moment.unix(storedLastRunAt)) : "n/a"
    }`
  ) : isActive ? (
    "Not saved yet"
  ) : title === "Custom Configuration" ? (
    <span className="text-[#337AB7]">
      <FontAwesomeIcon icon={faEdit} /> Click here to edit
    </span>
  ) : (
    "Try configuration"
  );
  return (
    <Link
      className={classNames(
        "block flex-1 !bg-[#f9fafb] hover:!bg-[#fcfcfc] !px-4 !py-3 text-center",
        isActive && "border-l-2 lg:border-l-0 lg:border-b-2 border-[#337AB7]",
      )}
      to={to}
    >
      <span className="block text-[#337AB7] text-lg leading-6 mb-1">
        {title}
        {badge}
      </span>
      <span className="block text-[#606060] text-sm leading-5">
        {description}
      </span>
    </Link>
  );
};

const SavingCallout: React.FunctionComponent<{
  schemaName: string;
  tableName: string;
  databaseId: string;
  tableId: string;
  settings?: string;
  preset?: string;
  defaultPreset?: string;
}> = ({
  schemaName,
  tableName,
  databaseId,
  tableId,
  settings,
  preset,
  defaultPreset,
}) => {
  const [saveConfiguration, { called, loading, error }] = useMutation<
    UpdateIndexingEngineSelectionOptionOverride,
    UpdateIndexingEngineSelectionOptionOverrideVariables
  >(MUTATION);
  useAsyncActionFlash({
    called: called,
    loading: loading,
    error: error?.message,
    success: "Configuration changes saved",
  });
  const handleButtonClick = () => {
    saveConfiguration({
      variables: {
        databaseId: databaseId,
        tableId: tableId,
        settings: preset ? null : settings,
        preset: preset,
      },
    });
  };
  const calloutButton = {
    label: "Save configuration",
    onClick: handleButtonClick,
  };
  const content =
    preset === "default" ? (
      <>
        Once the configuration is saved, the override configuration will be
        removed and the automatically selected configuration will be used
        (currently: {getNameByPreset(defaultPreset)}) for future checks.
      </>
    ) : preset === "ignored" ? (
      <>
        Once the configuration is saved, Index Advisor will not run for this
        table. Any existing insights will be resolved automatically.
      </>
    ) : (
      <>
        Once the configuration is saved, it will run the missing index check for
        this table in the background, and the configuration will be used for
        future checks.
      </>
    );
  return (
    <Callout
      title={`Index Advisor configuration for table ${schemaName}.${tableName} not saved yet`}
      variant="warning"
      className="mb-5"
      calloutButton={calloutButton}
    >
      {content}
    </Callout>
  );
};

const Summary: React.FunctionComponent<{
  loading: boolean;
  summaryConfig: IndexAdvisorConfigType;
}> = ({ summaryConfig, loading }) => {
  const data = summaryConfig?.getSchemaTableIndexAdvisorConfig;
  return (
    <PanelTileGroup className="mb-5">
      <PanelTile title="Table Size" loading={loading}>
        <span className="text-2xl">{formatBytes(data?.tableSize)}</span>
      </PanelTile>
      <PanelTile title="Writes per Minute" loading={loading}>
        <span className="text-2xl">
          {formatNumberWithThreshold(data?.writesPerMin, 1)}
        </span>
      </PanelTile>
      <PanelTile title="Scans per Minute" loading={loading}>
        <span className="text-2xl">
          {formatNumberWithThreshold(data?.scansPerMin, 1)}
        </span>
      </PanelTile>
      <PanelTile title="Queries" loading={loading}>
        <span className="text-2xl">{formatNumber(data?.queries)}</span>
      </PanelTile>
    </PanelTileGroup>
  );
};

export default SchemaTableIndexAdvisor;
