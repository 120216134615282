import PageContent from "components/PageContent";
import Panel from "components/Panel";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import QUERY from "./Query.graphql";
import CREATE_MUTATION from "./Mutation.create.graphql";
import DELETE_MUTATION from "./Mutation.delete.graphql";
import WORKSPACE_DETAIL_QUERY from "../Query.graphql";
import {
  QuerySamplesForWorkspace,
  QuerySamplesForWorkspaceVariables,
} from "./types/QuerySamplesForWorkspace";
import { formatTimestampShort } from "utils/format";
import moment from "moment";
import Grid, { MsCell } from "components/Grid";
import SQL from "components/SQL";
import Identicon from "components/Identicon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import PanelSection from "components/PanelSection";
import Button from "components/Button";
import { useRoutes } from "utils/routes";
import ExpandableSQL from "components/ExpandableSQL";
import FilterSearch from "components/FilterSearch";
import { ExplainWorkspaceDetails_getExplainWorkspaceDetails as ExplainWorkspaceType } from "../types/ExplainWorkspaceDetails";
import Loading from "components/Loading";
import {
  CreateExplainParameterSets,
  CreateExplainParameterSetsVariables,
} from "./types/CreateExplainParameterSets";
import {
  DeleteExplainParameterSets,
  DeleteExplainParameterSetsVariables,
} from "./types/DeleteExplainParameterSets";
import {
  JsonParametersType,
  convertParamValue,
  jsonParametersToString,
} from "../util";
import { ExplainWorkspaceHeader } from "..";

const ExplainWorkspaceParameterSets = ({
  workspace,
}: {
  workspace: ExplainWorkspaceType;
}) => {
  const { databaseId } = useParams();
  const [showParamsFromQuery, setShowParamsFromQuery] = useState(true);
  const [customParamsSet, setCustomParamsSet] = useState<{
    [key: string]: any;
  }>({});
  const [paramsFromSample, setParamsFromSample] = useState<JsonParametersType>(
    {},
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [customParamsQuery, setCustomParamsQuery] = useState("");
  const { databaseQueryExplain, databaseQueriesExplainsWorkspaces } =
    useRoutes();
  const baselineQuery = workspace.baselineQuery;

  const [createExplainParameterSets] = useMutation<
    CreateExplainParameterSets,
    CreateExplainParameterSetsVariables
  >(CREATE_MUTATION);
  const [deleteExplainParameterSets] = useMutation<
    DeleteExplainParameterSets,
    DeleteExplainParameterSetsVariables
  >(DELETE_MUTATION);

  const { loading, error, data } = useQuery<
    QuerySamplesForWorkspace,
    QuerySamplesForWorkspaceVariables
  >(QUERY, {
    variables: {
      databaseId,
      queryFingerprint: baselineQuery.queryFingerprint,
    },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const querySamples = data.getQuerySamples.filter(
    (sample) => sample.jsonParameters !== null && sample.explain !== null,
  );
  const filteredData = querySamples.filter((sample) =>
    jsonParametersToString(
      sample.jsonParameters,
      baselineQuery.paramRefAliasMap,
    ).includes(searchTerm),
  );
  const secondaryTitle = (
    <FilterSearch
      initialValue={searchTerm}
      onChange={setSearchTerm}
      placeholder="Search parameters..."
    />
  );

  const handleRunExplain = () => {
    createExplainParameterSets({
      variables: {
        databaseId,
        explainQueryId: baselineQuery.id,
        parameters: Object.values(paramsFromSample),
        finalize: true,
      },
      refetchQueries: [
        {
          query: WORKSPACE_DETAIL_QUERY,
          variables: { workspaceId: workspace.id, databaseId },
        },
      ],
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };
  const handleAddParamsFromQuery = () => {
    if (customParamsQuery == "") {
      setErrorMessage("Query is required");
      return;
    }
    createExplainParameterSets({
      variables: {
        databaseId,
        explainQueryId: baselineQuery.id,
        queryText: customParamsQuery,
      },
      onCompleted: () => {
        setErrorMessage("");
        setCustomParamsQuery("");
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };
  const handleCustomParamsChange = (ref: string, value: string) => {
    const newSet = { ...customParamsSet };
    if (value === "") {
      // remove from the set when the value becomes an empty string
      delete newSet[ref];
      setCustomParamsSet(newSet);
      return;
    }
    newSet[ref] = convertParamValue(value);
    setCustomParamsSet(newSet);
  };
  const handleAddParams = () => {
    if (
      Object.keys(customParamsSet).length !==
      Object.keys(baselineQuery.paramRefAliasMap).length
    ) {
      setErrorMessage(
        "Please enter values for all parameters. Use 'null' for NULL values.",
      );
      return;
    }
    createExplainParameterSets({
      variables: {
        databaseId,
        explainQueryId: baselineQuery.id,
        parameters: [customParamsSet],
      },
      onCompleted: () => {
        setErrorMessage("");
        setCustomParamsSet({});
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const queryWithNoParams =
    Object.keys(baselineQuery.paramRefAliasMap).length === 0;
  const selectedParameterSetsCount =
    workspace.parameterSets.length + Object.keys(paramsFromSample).length;
  const defaultExpandCustomParams =
    // there are custom params
    workspace.parameterSets.length > 0 ||
    // there are parameters but there is no query samples
    (Object.keys(baselineQuery.paramRefAliasMap).length > 0 &&
      querySamples.length === 0) ||
    queryWithNoParams;

  return (
    <PageContent
      windowTitle={`EXPLAIN Workspace: ${workspace.name}`}
      featureInfo={<ExplainWorkspaceHeader workspace={workspace} />}
      pageCategory="explains"
      pageName="workspaces"
      layout="sidebar"
    >
      {/* main content */}
      <div className="mb-4 leading-6">
        <ExpandableSQL sql={baselineQuery.queryTextWithAlias} />
      </div>
      {querySamples.length > 0 && (
        <Panel
          title="Select Parameters from Query Samples"
          secondaryTitle={secondaryTitle}
        >
          <Grid
            className="grid-cols-[1fr_140px_140px]"
            data={filteredData}
            pageSize={5}
            defaultSortBy={"runtimeMs"}
            columns={[
              {
                field: "jsonParameters",
                header: "Query Sample",
                renderer: function ParametersCell({ rowData, fieldData }) {
                  const jsonParams = jsonParametersToString(
                    fieldData,
                    baselineQuery.paramRefAliasMap,
                  );
                  return (
                    <div className="flex gap-2">
                      <div>
                        <input
                          type="checkbox"
                          id="sample_params"
                          checked={!!paramsFromSample[rowData.id]}
                          onChange={(evt) => {
                            const currParams = { ...paramsFromSample };
                            if (evt.target.checked) {
                              currParams[rowData.id] = fieldData;
                              setParamsFromSample(currParams);
                            } else {
                              delete currParams[rowData.id];
                              setParamsFromSample(currParams);
                            }
                          }}
                        />
                      </div>
                      <div title={jsonParams}>
                        <SQL className="!whitespace-nowrap" sql={jsonParams} />
                        <div className="text-[12px] text-[#606060]">
                          {formatTimestampShort(
                            moment.unix(rowData.occurredAt),
                          )}
                        </div>
                      </div>
                    </div>
                  );
                },
                disableSort: true,
              },
              {
                field: "explain",
                header: "Plan Fingerprint",
                renderer: function PlanFingerprintCell({ fieldData, rowData }) {
                  const fingerprint = fieldData.fingerprint;
                  return (
                    <Link
                      to={databaseQueryExplain(
                        databaseId,
                        baselineQuery.query.id,
                        rowData.explain.humanId,
                      )}
                    >
                      <Identicon identity={fingerprint} />
                      <span title={fingerprint}>
                        {fingerprint.substring(0, 7)}
                      </span>
                    </Link>
                  );
                },
              },
              {
                field: "runtimeMs",
                header: "Runtime",
                renderer: MsCell,
                defaultSortOrder: "desc",
              },
            ]}
          />
        </Panel>
      )}
      <Panel
        title="Add Custom Parameters"
        expandable
        defaultExpanded={defaultExpandCustomParams}
      >
        {queryWithNoParams ? (
          <PanelSection>No parameters with the query</PanelSection>
        ) : (
          <>
            <Grid
              className="grid-cols-[1fr_60px]"
              data={workspace.aliasParamMapList}
              noRowsText="No custom parameters"
              columns={[
                {
                  field: "parameters",
                  header: "Custom Parameters",
                  renderer: function ParametersCell({ fieldData }) {
                    return (
                      <div className="flex gap-2">
                        <div>
                          <input type="checkbox" checked disabled />
                        </div>
                        <SQL sql={jsonParametersToString(fieldData, null)} />
                      </div>
                    );
                  },
                  className: "whitespace-normal",
                },
                {
                  field: "id",
                  header: "",
                  renderer: function DeleteParamCell({ rowData }) {
                    return (
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        title="Delete"
                        className="text-[#CA1515] mr-1 cursor-pointer"
                        onClick={() => {
                          deleteExplainParameterSets({
                            variables: {
                              workspaceId: workspace.id,
                              parameterSetsId: rowData.id,
                            },
                            onCompleted: () => {
                              setErrorMessage("");
                            },
                            onError: (error) => {
                              setErrorMessage(error.message);
                            },
                          });
                        }}
                      />
                    );
                  },
                  className: "text-right",
                },
              ]}
            />
            <PanelSection>
              {showParamsFromQuery ? (
                <div className="grid grid-cols-2 items-center">
                  <div className="mb-1 font-medium col-span-2">
                    Custom query
                  </div>
                  <div className="col-span-2">
                    <textarea
                      className="bg-white rounded border border-gray-300 box-border w-full leading-5 px-2 py-1.5 disabled:bg-[#eee]"
                      placeholder="Paste query with parameter values..."
                      value={customParamsQuery}
                      onChange={(e) => setCustomParamsQuery(e.target.value)}
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-success"
                      onClick={handleAddParamsFromQuery}
                    >
                      Add parameters from query
                    </button>
                  </div>
                  <div className="justify-self-end">
                    <Button
                      bare
                      className="!text-[#337ab7] hover:underline"
                      onClick={() => setShowParamsFromQuery(false)}
                    >
                      Add parameters manually
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-2 gap-2 mb-2">
                    {Object.entries(baselineQuery.paramRefAliasMap).map(
                      ([ref, alias]) => {
                        return (
                          <div
                            className="grid grid-cols-[min-content_1fr] items-center gap-2"
                            key={`params${ref}`}
                          >
                            <div className="font-medium">{`${alias}`}</div>
                            <input
                              className="bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5"
                              type="text"
                              placeholder="Type 'null' for null value"
                              value={customParamsSet[ref] || ""}
                              onChange={(e) => {
                                handleCustomParamsChange(ref, e.target.value);
                              }}
                            />
                          </div>
                        );
                      },
                    )}
                  </div>
                  <div className="grid grid-cols-2 gap-2 items-center">
                    <div>
                      <button
                        className="btn btn-success"
                        onClick={handleAddParams}
                      >
                        Add parameters
                      </button>
                    </div>
                    <div className="justify-self-end">
                      <Button
                        bare
                        className="!text-[#337ab7] hover:underline"
                        onClick={() => setShowParamsFromQuery(true)}
                      >
                        Paste custom query
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </PanelSection>
          </>
        )}
      </Panel>
      {errorMessage && <div className="text-[#FF0000]">{errorMessage}</div>}
      <div className="grid grid-cols-2 items-center">
        <div>{selectedParameterSetsCount} parameter sets selected</div>
        <div className="justify-self-end">
          <Link
            to={databaseQueriesExplainsWorkspaces(databaseId)}
            className="px-10 text-[#273858] font-medium mr-1"
          >
            Cancel
          </Link>
          <button
            className="btn btn-success !px-4"
            disabled={!queryWithNoParams && selectedParameterSetsCount === 0}
            onClick={handleRunExplain}
          >
            Run EXPLAIN...
          </button>
        </div>
      </div>
      {/* sidebar */}
      {/* TODO: need to think about how to make sidebar's divider visible until the end of the screen */}
      <div className="w-[320px]">
        <h4 className="leading-7 mt-0">Parameter Sets</h4>
        <p>
          Parameter sets refer to the use of placeholders in SQL queries,
          allowing for the dynamic substitution of values at execution time to
          enhance security and performance. This technique helps prevent SQL
          injection attacks and improves query efficiency by reusing the
          execution plan with different parameter values.
        </p>
        <h4 className="leading-7">Query Samples</h4>
        <p>
          Query samples in PostgreSQL refer to examples or instances of SQL
          queries that are used for testing, optimization, or benchmarking
          purposes. These samples help in analyzing query performance,
          identifying potential bottlenecks, and ensuring that the database can
          handle various types of queries efficiently.
        </p>
      </div>
    </PageContent>
  );
};

export default ExplainWorkspaceParameterSets;
