import React from "react";
import { useQuery } from "@apollo/client";

import Panel from "components/Panel";

import { formatBytes, formatNumber, formatMs } from "utils/format";

import { Data } from "components/Graph/util";
import { useDateRange } from "components/WithDateRange";

import {
  LineSeries,
  AreaSeries,
  ThresholdSeries,
} from "components/Graph/Series";

import {
  StorageAndIO as StorageAndIOType,
  StorageAndIOVariables,
} from "./types/StorageAndIO";
import QUERY from "./Query.graphql";
import GraphSection from "components/Graph/GraphSection";
import DateRangeGraph from "components/Graph/DateRangeGraph";

type Props = {
  amazonRdsEnhanced: boolean;
  serverId: string;
  isAuroraPostgres: boolean;
  storageMountpoint: string;
  systemType: string;
};

const StorageAndIO: React.FunctionComponent<Props> = ({
  amazonRdsEnhanced,
  serverId,
  isAuroraPostgres,
  storageMountpoint,
  systemType,
}) => {
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;

  const { data, loading, error } = useQuery<
    StorageAndIOType,
    StorageAndIOVariables
  >(QUERY, {
    variables: {
      serverId,
      startTs: newStartTs.unix(),
      endTs: newEndTs.unix(),
    },
  });

  const noData = !loading && !error && data.getSystemStats == null;

  const storageSpaceChart = (
    <Panel
      title={
        (systemType == "physical" &&
          storageMountpoint &&
          `Storage Space for ${storageMountpoint}`) ||
        "Storage Space"
      }
    >
      <GraphSection noData={noData} loading={loading} error={error}>
        <DateRangeGraph
          data={data?.getSystemStats as unknown as Data}
          axes={{
            left: {
              format: formatBytes,
            },
          }}
          series={[
            {
              type: LineSeries,
              key: "storageSpaceTotal",
              label: "Total Storage",
              tipLabel: "Total",
            },
            {
              type: AreaSeries,
              key: "storageSpaceUsed",
              label: "Used Storage",
              tipLabel: "Used",
            },
            {
              type: AreaSeries,
              key: "storageSpaceFree",
              label: "Free Storage",
              tipLabel: "Free",
            },
          ]}
        />
      </GraphSection>
    </Panel>
  );

  const storageUsageChart = (
    <Panel title={"Storage Usage"}>
      <GraphSection noData={noData} loading={loading} error={error}>
        <DateRangeGraph
          data={data?.getSystemStats as unknown as Data}
          axes={{
            left: {
              format: formatBytes,
            },
          }}
          series={[
            {
              type: AreaSeries,
              key: "storageSpaceUsed",
              label: "Used Storage",
              tipLabel: "Used",
              color: "orange",
            },
          ]}
        />
      </GraphSection>
    </Panel>
  );

  const ioUtilizationChart = (
    <Panel title="I/O Utilization">
      <GraphSection noData={noData} loading={loading} error={error}>
        <DateRangeGraph
          data={data?.getSystemStats as unknown as Data}
          axes={{
            left: {
              format: "pct from ratio",
            },
          }}
          series={[
            {
              type: AreaSeries,
              key: "storageUtilization",
              label: "% Utilization",
              color: "#e3b514",
            },
            {
              type: ThresholdSeries,
              key: "storageUtilizationPct90",
              label: "90% Limit",
              tipLabel: "90%",
              color: "grey",
            },
            {
              type: ThresholdSeries,
              key: "storageUtilizationPct100",
              label: "100% Limit (Overloaded)",
              tipLabel: "100%",
              color: "red",
            },
          ]}
        />
      </GraphSection>
    </Panel>
  );

  const iopsChart = (
    <Panel title="IOPS">
      <GraphSection noData={noData} loading={loading} error={error}>
        <DateRangeGraph
          data={data?.getSystemStats as unknown as Data}
          axes={{
            left: {
              tipFormat: (y: number): string => formatNumber(y) + " / sec",
            },
          }}
          series={[
            {
              type: LineSeries,
              key: "storageIopsRead",
              label: "Read IOPS",
            },
            {
              type: LineSeries,
              key: "storageIopsWrite",
              label: "Write IOPS",
            },
          ]}
        />
      </GraphSection>
    </Panel>
  );

  const ioLatencyChart = (
    <Panel title="I/O Latency">
      <GraphSection noData={noData} loading={loading} error={error}>
        <DateRangeGraph
          data={data?.getSystemStats as unknown as Data}
          axes={{
            left: {
              format: (y: number): string => formatMs(y),
            },
          }}
          series={[
            {
              type: LineSeries,
              key: "storageLatencyRead",
              label: "Average Read Wait",
              tipLabel: "Read Wait",
            },
            {
              type: LineSeries,
              key: "storageLatencyWrite",
              label: "Average Write Wait",
              tipLabel: "Write Wait",
            },
          ]}
        />
      </GraphSection>
    </Panel>
  );

  const ioThroughputChart = (
    <Panel title="I/O Throughput">
      <GraphSection noData={noData} loading={loading} error={error}>
        <DateRangeGraph
          data={data?.getSystemStats as unknown as Data}
          axes={{
            left: {
              format: "bytes/sec",
            },
          }}
          series={[
            {
              key: "storageThroughputRead",
              label: "Read Throughput",
              tipLabel: "Read",
            },
            {
              key: "storageThroughputWrite",
              label: "Write Throughput",
              tipLabel: "Write",
            },
          ]}
        />
      </GraphSection>
    </Panel>
  );

  const ioQueueDepthChart = (
    <Panel title="I/O Queue Depth">
      <GraphSection noData={noData} loading={loading} error={error}>
        <DateRangeGraph
          data={data?.getSystemStats as unknown as Data}
          axes={{
            left: {
              format: formatNumber,
            },
          }}
          series={[
            {
              key: "storageQueueDepth",
              label: "IOPS in Progress",
              tipLabel: "IOPS",
            },
          ]}
        />
      </GraphSection>
    </Panel>
  );

  // Heroku Postgres has very limited I/O and Storage metrics
  if (systemType == "heroku") {
    return iopsChart;
  }

  // Tembo currently has limited Storage metrics
  if (systemType == "tembo") {
    return storageSpaceChart;
  }

  return (
    <>
      {isAuroraPostgres ? storageUsageChart : storageSpaceChart}
      {(systemType != "amazon_rds" || amazonRdsEnhanced) && ioUtilizationChart}
      {iopsChart}
      {ioLatencyChart}
      {ioThroughputChart}
      {ioQueueDepthChart}
    </>
  );
};

export default StorageAndIO;
